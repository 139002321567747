/* eslint-disable no-nested-ternary */
import { Grid } from '@mui/material';
import React from 'react';
import '../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ReactComponent as OrangeRuppee } from '../../../../../common/icons/small-orange-rupee.svg';
import { ReactComponent as Premium } from '../../../../../common/icons/dashboard/pay_premiums-icon.svg';
import theme from '../../../../../common/theme';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import SvgConverter from '../../../../../common/SvgConverter';
import { convertAmount, getFormatDate } from '../../../../../common/utils';

interface RenewalAmountProps {
	renewalDetails?: any;
	policyType?: any;
}
const RenewalAmount: React.FC<RenewalAmountProps> = ({
	renewalDetails,
	policyType,
}) => {
	const formattedPremiumDue =
		policyType === 'life'
			? renewalDetails && renewalDetails?.premiumDueBy
				? getFormatDate(
						renewalDetails && renewalDetails?.premiumDueBy,
						'YYYY-MM-DD'
				  )
				: '-'
			: renewalDetails && renewalDetails?.renewalDueBy
			? getFormatDate(
					renewalDetails && renewalDetails?.renewalDueBy,
					'YYYY-MM-DD'
			  )
			: '-';
	const { t } = useTranslation();

	const isPastDate =
		formattedPremiumDue === 'Invalid Date' ||
		new Date(formattedPremiumDue) > new Date();

	return (
		<Grid item container xs={12}>
			<Grid container item xs={12} className=" py-10">
				<Grid item container xs={12} className="blue-box  py-5  px-5">
					<Grid container item xs={5}>
						<Grid item xs={2.5}>
							<SvgConverter Icon={Premium} className="ml-2 mt-5" />
						</Grid>
						<Grid item xs={9}>
							<Typography
								className="f-12 text-wrap"
								color={theme.palette.primary.main}
							>
								{policyType === 'life' ? t('PREMIUM') : t('RENEWAL_AMOUNT')}
							</Typography>
							<LabelWithIcon
								svgClassName="mb-2"
								justifyContent="flex-start"
								Icon={OrangeRuppee}
								label={
									policyType === 'life'
										? renewalDetails?.premiumAmount?.length === 0
											? '-'
											: convertAmount(renewalDetails?.premiumAmount)
										: renewalDetails?.renewalAmount?.length === 0
										? '-'
										: convertAmount(renewalDetails?.renewalAmount)
								}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 14,
									fontWeight: 600,
								}}
							/>
						</Grid>
					</Grid>
					<Grid item container xs={7}>
						<Grid item xs={6} sm={6}>
							<Typography
								className="f-14 mt-10 ml-25"
								color={theme.palette.primary.main}
								style={{ wordWrap: 'break-word' }}
							>
								
								{t('DUE_BY')}
								{/* {isPastDate ? t('WAS_DUE_BY') : t('DUE_BY')} */}
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6}>
							<Typography
								className="f-14 mt-10 ml-5"
								color={theme.palette.primary.main}
							>
								{formattedPremiumDue === 'Invalid Date'
									? '-'
									: formattedPremiumDue}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default RenewalAmount;
