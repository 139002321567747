import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import Separator from '../../../../../../common/ui/Separator';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import { convertAmount } from '../../../../../../common/utils';

interface CoPaymentProps {
	paymentDetails?: any;
	health?: any;
}
const CoPayment: React.FC<CoPaymentProps> = ({ paymentDetails, health }) => {
	const { t } = useTranslation();

	return (
		<Grid container>
			{health ? (
				<>
					<Grid item xs={12} className="py-5">
						{/* {paymentDetails?.coPayementPercent?.length !== 0 && ( */}
						<>
							<Typography
								color={theme.palette.primary.main}
								fontSize={14}
								fontWeight={600}
							>
								{t('CO_PAYMENT')} %
							</Typography>

							<Typography color={theme.palette.common.black} fontSize={18}>
								{/* {paymentDetails?.coPayementPercent
									? paymentDetails?.coPayementPercent : ${t('%')}
									: t('NO_COPAY')}
								&nbsp;% */}
								{paymentDetails?.coPayementPercent
									? `${paymentDetails.coPayementPercent}  ${t('%')} ${t(
											'CO_PAY'
									  )} `
									: t('NO_COPAY')}
							</Typography>
						</>
						{/* )} */}
					</Grid>
					<Grid item xs={12} className="py-5">
						<Typography
							color={theme.palette.primary.main}
							fontSize={14}
							fontWeight={600}
						>
							{t('VOLUNTARY_DEDUCTIBLE')} %
						</Typography>
						<Typography color={theme.palette.common.black} fontSize={18}>
							{paymentDetails?.voluntaryDeductiblepercent
								? paymentDetails?.voluntaryDeductiblepercent
								: '0'}
							&nbsp;%
						</Typography>
					</Grid>
				</>
			) : (
				<>
					<Grid item xs={4} className="py-5">
						<Typography
							color={theme.palette.primary.main}
							fontSize={14}
							fontWeight={600}
						>
							{t('DEDUCTIBLE')} %
						</Typography>{' '}
						<Typography color={theme.palette.common.black} fontSize={18}>
							{paymentDetails?.deductiblePercent?.length === 0
								? '0'
								: convertAmount(paymentDetails?.deductiblePercent)}{' '}
							%
						</Typography>
					</Grid>{' '}
					<Grid item xs={8} className="py-5">
						<Typography
							color={theme.palette.primary.main}
							fontSize={14}
							fontWeight={600}
						>
							{t('GEOGRAPHICAL_AREAS_COVERED')}
						</Typography>{' '}
						<Typography color={theme.palette.common.black} fontSize={18}>
							{paymentDetails?.geographicalAreasCovered
								? paymentDetails?.geographicalAreasCovered
								: '-'}
						</Typography>
					</Grid>
					<Grid item xs={12} className="py-5">
						<Typography
							color={theme.palette.primary.main}
							fontSize={14}
							fontWeight={600}
						>
							{t('VOLUNTARY_DEDUCTIBLE')} %
						</Typography>
						<Typography color={theme.palette.common.black} fontSize={18}>
							{paymentDetails?.voluntaryDeductiblepercent?.length === 0
								? '0'
								: paymentDetails?.voluntaryDeductiblepercent}{' '}
							%
						</Typography>
					</Grid>
				</>
			)}
			<Grid item xs={12} className="py-10 ">
				<Separator color={theme.palette.grey[400]} />
			</Grid>
			<Grid item xs={6} className="py-5">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('TOTAL_PREMIUM')}
				</Typography>{' '}
				<LabelWithIcon
					className=""
					svgClassName="mb-2 "
					justifyContent="flex-start"
					Icon={OrangeRuppee}
					label={
						paymentDetails?.totalPremium?.length === 0
							? '-'
							: convertAmount(paymentDetails?.totalPremium)
					}
					labelProps={{
						color: theme.palette.common.black,
						fontSize: 18,
						fontWeight: 600,
					}}
				/>
			</Grid>
			<Grid item xs={6} className="py-5">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('PAYMENT_MODE')}
				</Typography>{' '}
				<Typography color={theme.palette.common.black} fontSize={18}>
					{paymentDetails?.paymentMode ? paymentDetails?.paymentMode : '-'}
				</Typography>
			</Grid>{' '}
			<Grid item xs={12} className="py-10 ">
				<Separator color={theme.palette.grey[400]} />
			</Grid>
		</Grid>
	);
};

export default CoPayment;
