import React from 'react';
import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../payment.scss';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { ReactComponent as OrangeRupee } from '../../../../common/icons/medium_rupee_icon.svg';
import { ReactComponent as CancelBig } from '../../../../common/icons/cancel-red-big.svg';
import { ReactComponent as Circle } from '../../../../common/icons/payment_fail_bg.svg';
import CustomButton from '../../../../common/ui/CustomButton';
import SvgConverter from '../../../../common/SvgConverter';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { convertAmount } from '../../../../common/utils';

const PaymentFailed = ({ amount, transactionId }: any) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<>
			<Grid container justifyContent="center" alignItems="center">
				<Grid item xs={12} className="payment-card-success mt-30">
					<Grid container justifyContent="center">
						<Grid item style={{ position: 'relative' }}>
							<SvgConverter
								Icon={CancelBig}
								className="payment-success-failed-icon-position"
							/>
							<SvgConverter Icon={Circle} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				justifyContent="center"
				textAlign="center"
				className="mt-10"
			>
				<Grid item>
					<LabelWithIcon
						svgClassName="mb-2"
						justifyContent="center"
						Icon={OrangeRupee}
						label={convertAmount(amount)}
						labelProps={{
							color: theme.palette.common.black,
							fontSize: 20,
							fontWeight: 600,
						}}
					/>
					<Typography fontSize={16} fontWeight={500} color="  #d9001b">
						{t('PREMIUM_PAYMENT_FAILED')}
					</Typography>
					<Typography
						fontSize={14}
						color={theme.palette.common.black}
						className="mt-15"
					>
						{t('REFERENCE_ID')}{' '}
					</Typography>{' '}
					<Typography
						fontSize={14}
						fontWeight={600}
						color={theme.palette.common.black}
						className="mb-20"
					>
						{transactionId}
					</Typography>
					<Typography
						fontSize={20}
						color={theme.palette.common.black}
						lineHeight={1.3}
						className="mb-20"
					>
						{t('ISSUE_WITH_TRANSACTION')}
					</Typography>
					<Typography
						fontSize={20}
						color={theme.palette.common.black}
						lineHeight={1.3}
						className="mb-20"
					>
						{t('TRY_AGAIN')}
					</Typography>
				</Grid>
			</Grid>
			{/* <Grid container justifyContent="right" textAlign="right">
				<Grid item justifyContent="right" textAlign="right">
					<Link to="/" replace>
						<CustomButton
							className=" fw-600"
							text={t('CLICK__TO_GO_BACK')}
							variant="text"
							color="primary"
							showIcon={false}
						/>
					</Link>
				</Grid>
			</Grid> */}
		</>
	);
};
export default PaymentFailed;
