import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import '../../../Policy.scss';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IIcon } from '../../../../../../common/icons/i-icon.svg';
import {
	fetchViewPolicyDetails,
	viewPolicyList,
	viewPolicyLoading,
} from '../../../Policies.slice';
import Separator from '../../../../../../common/ui/Separator';
// import CustomButton from '../../../../../../common/ui/CustomButton';
import SvgConverter from '../../../../../../common/SvgConverter';
import theme from '../../../../../../common/theme';
import PolicyDetailsHeader from '../PolicyDetailsHeader';
import InsuredPerson from '../common/InsuredPerson';
import Addon from '../common/Addon';
import TermsCondition from '../common/TermsCondition';
import CoPayment from '../common/CoPayment';
import Communication from '../common/CommunicationDetails';
import DownloadStatements from '../Life/DownloadStatements';
import VehicleInformation from './VehicleInformation';
import AssetClaim from './AssetClaim';
import AssetFooter from './AssetFooter';
import RenewalAmount from '../RenewalAmount';
import BranchCode from '../common/BranchCode';
import StampDetails from '../common/StampDetails';
import DownloadDocument from '../Life/DownloadDocument';
import PolicyDetailsData from '../common/PolicyDetailsData';
import Spinner from '../../../../../../common/ui/Spinner';
import CustomButton from '../../../../../../common/ui/CustomButton';
import Nominees from '../Life/Nominees';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';

const AssetDetails = () => {
	const location = useLocation();
	const policyDetails = location.state.items;
	const isArchive = location.state.footerType === 'archive';
	const policyNum = policyDetails?.policyNo;
	const altCode = policyDetails?.alternateCode;
	const insCode = policyDetails?.companyCode
		? policyDetails?.companyCode
		: policyDetails?.insurerCode;
	const VerifiedFlag = policyDetails?.verifiedFlag;
	const dispatch = useDispatch();
	const assetPolicyDataLoading = useSelector(viewPolicyLoading);
	const assetPolicyData = useSelector(viewPolicyList);
	const assetPolicyDetails =
		assetPolicyData?.policyDetails || assetPolicyData?.policyDetailsIns;
	const assetData = location.state;

	useEffect(() => {
		dispatch(
			fetchViewPolicyDetails({
				policyNum,
				altCode,
				insCode,
				VerifiedFlag,
			} as any)
		);
	}, []);

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Asset Policy Screen',
		});
	}, []);


	return (
		<Grid container justifyContent="center" alignItems="center">
			<Grid container item className="policy-details" mb={5}>
				<PolicyDetailsHeader />
				{assetPolicyDataLoading ? (
					<Grid
						item
						xs={12}
						justifyContent="center"
						display="flex"
						alignItems="center"
						marginTop={10}
					>
						<Spinner />
					</Grid>
				) : (
					<Grid item xs={12} container columnSpacing={3} rowSpacing={1}>
						<Grid container item lg={4} xs={12} md={4} xl={12} sm={6}>
							<Grid item xs={12}>
								<PolicyDetailsData
									policyDetails={assetPolicyDetails}
									detailType="asset"
								/>
								<RenewalAmount renewalDetails={assetPolicyDetails} />
								<Grid
									container
									item
									xs={12}
									className="py-10 separator-mobile "
								>
									<Separator color={theme.palette.grey[400]} />
								</Grid>
							</Grid>
						</Grid>
						<Grid container item lg={4} xs={12} md={4} xl={4} sm={6}>
							<Grid item xs={12} container>
								<InsuredPerson
									insuredDetails={assetPolicyDetails}
									detailType="asset"
								/>{' '}
								<Grid container item xs={12} className="py-10 ">
									<Separator color={theme.palette.grey[400]} />
								</Grid>
								{assetPolicyDetails?.addon?.length !== 0 && (
									<>
										<Addon
											addonDetails={assetPolicyDetails?.addon}
											detailType={assetPolicyDetails?.alternateCode}
										/>
										{/* <Grid container item xs={12} className="py-10">
											<Separator color={theme.palette.grey[400]} />
										</Grid> */}
									</>
								)}
							</Grid>
						</Grid>
						{assetPolicyDetails?.termCondtions?.length !== 0 || (
							<Grid container item lg={4} xs={12} md={4} xl={12} sm={6}>
								<Grid item xs={12} className="mt-10">
									<TermsCondition
										termsConditionDetails={assetPolicyDetails?.termCondtions}
									/>
								</Grid>
								<Grid
									container
									item
									xs={12}
									className="py-10 separator-mobile "
								>
									<Separator color={theme.palette.grey[400]} />
								</Grid>
							</Grid>
						)}
						<Grid
							container
							item
							lg={4}
							xs={12}
							md={4}
							xl={12}
							sm={6}
							className="mt-5"
						>
							<Grid item xs={12}>
								<BranchCode branchDetails={assetPolicyDetails} />{' '}
							</Grid>
						</Grid>
						<Grid container item xs={12} className="py-10 separator-small">
							<Separator color={theme.palette.grey[400]} />
						</Grid>
						<Grid container item lg={4} xs={12} md={4} xl={12} sm={6}>
							<CoPayment paymentDetails={assetPolicyDetails} />
							<Communication
								communicationDetails={assetPolicyDetails}
								isArchive={isArchive}
							/>
							<Grid container item xs={12} className="py-10 separator-mobile ">
								<Separator color={theme.palette.grey[400]} />
							</Grid>
						</Grid>
						<Grid container item lg={4} xs={12} md={4} xl={12} sm={6}>
							<VehicleInformation
								vehicleDetails={assetPolicyDetails?.vehicleInformation}
							/>
						</Grid>
						<Grid container item xs={12} className="py-10 separator-large ">
							<Separator color={theme.palette.grey[400]} />
						</Grid>
						<Grid container item xs={12} className="py-10 separator-small">
							<Separator color={theme.palette.grey[400]} />
						</Grid>
						<Grid container item lg={4} xs={12} md={4} xl={12} sm={6}>
							<AssetClaim claimDetails={assetPolicyDetails?.claimHistory} />
							<Grid container item xs={12} className="py-10  ">
								<Separator color={theme.palette.grey[400]} />
							</Grid>
							<AssetFooter assertFooterDetails={assetPolicyDetails} />
							<Grid container item xs={12} className="py-10 separator-mobile ">
								<Separator color={theme.palette.grey[400]} />
							</Grid>
						</Grid>
						<Grid container item lg={4} xs={12} md={4} xl={4} sm={6}>
							<Grid item xs={12}>
								{' '}
								<Grid container item xs={12} className="separator-small">
									<Separator color={theme.palette.grey[400]} />
								</Grid>
								{assetPolicyDetails?.Nominees?.length !== 0 && (
									<Nominees nomineeDetails={assetPolicyDetails?.Nominees} />
								)}
							</Grid>
						</Grid>{' '}
						<Grid container item lg={4} xs={12} md={4} xl={12} sm={6}>
							<Grid item xs={12}>
								<StampDetails stampData={assetPolicyDetails} />
								<DownloadDocument
									downloadDocumentDetails={assetPolicyDetails}
									downloadData={assetData}
								/>
								<Grid container item xs={12} className="py-10 separator-mobile">
									<Separator color={theme.palette.grey[400]} />
								</Grid>
							</Grid>
						</Grid>
						<Grid
							container
							item
							lg={4}
							xs={12}
							md={4}
							xl={12}
							sm={6}
							className="mt-5"
						>
							<DownloadStatements
								downloadStatementsDetails={assetPolicyDetails?.Statements}
								downloadData={assetData}
							/>
						</Grid>
						<Grid container item xs={12} className="py-10 ">
							<Separator color={theme.palette.grey[400]} />
						</Grid>
						<Grid
							container
							item
							xs={11}
							display="flex"
							justifyContent="space-between"
						>
							<CustomButton
								text="CANCEL POLICY"
								variant="text"
								color="primary"
								showIcon={false}
								fontSize={14}
							/>
							<SvgConverter Icon={IIcon} className="mr-15" />
						</Grid>
						<Grid container item xs={12} className="py-10 ">
							<Separator color={theme.palette.grey[400]} />
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default AssetDetails;
