import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider, Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DashboardCardLayout from './common/DashboardCardLayout';
import PolicyCardHeader from './common/PolicyCardHeader';
import PolicyCardCost from './common/PolicyCardCost';
import LifeProgressBarWithValues from '../../../common/ui/LifeProgressBarWithValues';
import { convertAmount, getFormatDate } from '../../../common/utils';
import './Dashboard.scss';
import LifeProgressBarNon from '../../../common/ui/LifeProgressBarNon';
import Spinner from '../../../common/ui/Spinner';

interface UserProps {
	lifeDetails?: any;
	dashboardLoading?: any;
}
const LifeScreen: React.FC<UserProps> = ({ lifeDetails, dashboardLoading }) => {
	const theme = useTheme();

	// eslint-disable-next-line no-unsafe-optional-chaining
	const total =
		(parseInt(lifeDetails?.savings?.fundValueAmount) ?? 0) +
		(parseInt(lifeDetails?.savings?.surrenderValueAmount) ?? 0);
	const progressVal =
		(parseInt(lifeDetails?.savings?.guaranteedCurrentValue) ?? 1) /
		(parseInt(lifeDetails?.savings?.totalMaturityValueAmount) ?? 1); // Use 1 as the default value to avoid division by zero.
	const projectedProgressValue =
		(parseInt(lifeDetails?.savings?.nonGuaranteedCurrentValue) ?? 1) /
		(parseInt(lifeDetails?.savings?.maximumProjectedMaturityAmount) ?? 1);

	const calculatePolicyCounts = (counts: any) => {
		switch (counts.length) {
			case 0:
				return 0;
			default:
				return counts;
		}
	};
	const { t } = useTranslation();
	return (
		<DashboardCardLayout>
			<div className="mb-30 life-res">
				<PolicyCardHeader type="life" />
			</div>

			<Grid
				container
				flexDirection="row"
				spacing={3}
				justifyContent="space-between"
			>
				<Grid item className="mb-20">
					<div className="w-280px">
						<PolicyCardCost
							title="CURRENT_TOTAL_LIFE_COVER"
							value={
								lifeDetails?.totalLifeCoverAmount
									? convertAmount(lifeDetails?.totalLifeCoverAmount)
									: '0'
							}
							policyCounts={lifeDetails?.countLifeCover}
							type="life"
						/>
					</div>
				</Grid>
				{lifeDetails?.totalAccidentCoverAmount && (
					<Grid item className="mb-20">
						<div className="w-280px">
							<PolicyCardCost
								title="CURRENT_TOTAL_ACCIDENT_COVER"
								value={
									lifeDetails?.totalAccidentCoverAmount
										? convertAmount(lifeDetails?.totalAccidentCoverAmount)
										: '0'
								}
								policyCounts={lifeDetails?.countAccidentCover}
								type="life"
							/>
						</div>
					</Grid>
				)}
				{lifeDetails?.totalDisabilityCoverAmount && (
					<Grid item className="mb-20">
						<div className="w-280px">
							<PolicyCardCost
								title={t("CURRENT_TOTAL_DISABILITY_COVER")}
								value={
									lifeDetails?.totalDisabilityCoverAmount
										? convertAmount(lifeDetails?.totalDisabilityCoverAmount)
										: '0'
								}
								policyCounts={lifeDetails?.countDisabilityCover}
								type="life"
							/>
						</div>
					</Grid>
				)}
			</Grid>

			<Grid
				className="progress-res"
				container
				flexDirection="row"
				justifyContent="space-between"
			>
				{dashboardLoading ? (
					<Grid item display="flex" justifyContent="center" className="py-10">
						<Spinner />
					</Grid>
				) : (
					<>
						<Grid item>
							<Box className="dashboard-policy-cost-container mb-20">
								<Typography
									fontWeight={600}
									className="pb-1 mb-15 mt-20"
									fontSize={14}
									color={theme.palette.common.black}
								>
									{t('SAVINGS')}
								</Typography>
								<Typography
									fontWeight={600}
									className="pb-1"
									fontSize={16}
									color={theme.palette.primary.main}
								>
									{t('GUARANTEED')}
								</Typography>
								<Typography
									fontWeight={400}
									className="pb-1"
									fontSize={16}
									color={theme.palette.primary.main}
								>
									[ {t('NON_PARTICIPATORY')} + {t('PARTICIPATORY')}]
								</Typography>
							</Box>
							<Grid item className="mb-50" display="flex">
								<Divider
									orientation="vertical"
									sx={{
										height: '80px',
										backgroundColor: '#0051ba',
										width: '1px',
									}}
								/>
								<div className="progress_border_main" />

								<div className="w-280px">
									<PolicyCardCost.Normal
										titleFontWeight={400}
										title="TOTAL_MATURITY_VALUE"
										value={
											lifeDetails?.savings?.totalMaturityValueAmount
												? convertAmount(
														lifeDetails?.savings?.totalMaturityValueAmount
												  )
												: '0'
										}
										policyCounts={lifeDetails?.savings?.countMaturityValue}
									/>
									<Grid>
										<Grid className="progress-width">
											<LifeProgressBarWithValues
												currentValue={
													lifeDetails?.savings?.guaranteedCurrentValue
												}
												totalMaturityValueAmount={
													lifeDetails?.savings?.totalMaturityValueAmount
												}
												progressVal={progressVal || 0}
												total={total || 0}
												updatedDate={
													lifeDetails?.savings?.lastUpdatedDate
														? getFormatDate(
																lifeDetails?.savings?.lastUpdatedDate,
																'YYYY-MM-DD'
														  )
														: '-'
												}
											/>
										</Grid>
									</Grid>

									<Grid
										item
										container
										alignItems="center"
										spacing={2}
										justifyContent="flex-end"
									/>
									<Grid
										item
										container
										alignItems="center"
										spacing={2}
										justifyContent="flex-end"
									/>
								</div>
							</Grid>
						</Grid>
						<Grid className="mt-50 non_height newprogressStyle ">
							<Grid
								container
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<Grid item>
									<Grid>
										<Typography
											fontWeight={600}
											className="pb-1 "
											fontSize={16}
											color={theme.palette.primary.main}
										>
											{t('NON_GUARANTEED')}
										</Typography>
										<Typography
											fontWeight={400}
											className="pb-1"
											fontSize={16}
											color={theme.palette.primary.main}
										>
											[ {t('PARTICIPATORY')}+ {t('MARKET_LINKED')}]
										</Typography>
									</Grid>
								</Grid>
								<Grid
									container
									item
									className="mt-18 ml-45 resp-style"
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Grid display="flex">
										<div className="progress_three" />
										<div className="progress_two" />
										<div className="progress_one" />
									</Grid>
									<Grid item className="mb-20" display="flex">
										<Divider
											orientation="vertical"
											sx={{
												height: '202px',
												backgroundColor: '#0051ba',
												width: '1px',
												marginTop: 1,
											}}
										/>
										<div className="progress_border" />
										<div className="progress_border_box">
											<Grid style={{ position: 'relative' }}>
												<Grid className="progress_line">
													<Divider
														orientation="vertical"
														sx={{
															height: '100px',
															backgroundColor: '#0051ba',
															width: '1px',
															marginTop: 1,
														}}
													/>
													<div className="progress_border1" />
												</Grid>
												<Grid className="nonguaranteedstyle">
													<PolicyCardCost.NormalNonGuaranteed
														fontStyle="italic"
														titleFontWeight={400}
														title={`${t('PROJECTED_MATURITY')} @8%`}
														value={
															lifeDetails?.savings
																?.maximumProjectedMaturityAmount
																? convertAmount(
																		lifeDetails?.savings
																			?.maximumProjectedMaturityAmount
																  )
																: '0'
														}
														policyCounts={
															lifeDetails?.savings
																?.countMaximumProjectedMaturity
																? calculatePolicyCounts(
																		lifeDetails?.savings
																			?.countMaximumProjectedMaturity
																  )
																: 0
														}
													/>
												</Grid>
												<Grid className="second_content">
													<PolicyCardCost.NormalNonGuaranteed
														fontStyle="italic"
														titleFontWeight={400}
														title={`${t('PROJECTED_MATURITY')} @4%`}
														value={
															lifeDetails?.savings
																?.minimumProjectedMaturityAmount
																? convertAmount(
																		lifeDetails?.savings
																			?.minimumProjectedMaturityAmount
																  )
																: '0'
														}
														policyCounts={
															lifeDetails?.savings
																?.countMinimumProjectedMaturity
																? calculatePolicyCounts(
																		lifeDetails?.savings
																			?.countMinimumProjectedMaturity
																  )
																: 0
														}
													/>
												</Grid>
											</Grid>

											<Grid>
												<Grid className="progress-width mb-20">
													<LifeProgressBarNon
														currentValue={
															lifeDetails?.savings?.nonGuaranteedCurrentValue
																.length === 0
																? 0
																: lifeDetails?.savings
																		?.nonGuaranteedCurrentValue
														}
														progressVal={projectedProgressValue || 0}
														total={total || '0'}
														allValueText={`${t('ALL_FUND_VALUES')} + ${t(
															'BONUSES'
														)}`}
														updatedDate={
															lifeDetails?.savings?.lastUpdatedDate
																? getFormatDate(
																		lifeDetails?.savings?.lastUpdatedDate,
																		'YYYY-MM-DD'
																  )
																: '-'
														}
													/>
												</Grid>
											</Grid>

											<Grid
												item
												container
												alignItems="center"
												spacing={2}
												justifyContent="flex-end"
											/>
											<Grid
												item
												container
												alignItems="center"
												spacing={2}
												justifyContent="flex-end"
											/>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</>
				)}
			</Grid>
		</DashboardCardLayout>
	);
};

export default LifeScreen;
