/* eslint-disable new-cap */
import React from 'react';
import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../payment.scss';
import Typography from '@mui/material/Typography';
import { Link, useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { ReactComponent as OrangeRupee } from '../../../../common/icons/medium_rupee_icon.svg';
import { ReactComponent as GreenTick } from '../../../../common/icons/Big-Green-Tick.svg';
import { ReactComponent as Circle } from '../../../../common/icons/background-circle.svg';
import CustomButton from '../../../../common/ui/CustomButton';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import Separator from '../../../../common/ui/Separator';
import SvgConverter from '../../../../common/SvgConverter';
import { convertAmount } from '../../../../common/utils';
import { selectIsUserLoggedIn } from '../../../Auth/auth.slice';
import { selectUploadRenewalPaymentData } from '../Payment.slice';
import { ReactComponent as BemaCentral } from '../../../../assets/images/BimaLogo.svg';
import { openPdf } from '../../Common/pdf.slice';
import PDFModal from '../../../../common/ui/Modal/pdfModal';
import { setAlertInfo } from '../../Common/Common.slice';
import ApiModal from '../../../../common/ui/Modal/ApiModal';

const PaymentSuccess = ({ amount, transactionId, state, type, paymentResponse }: any) => {
  const loggedUser = useSelector(selectIsUserLoggedIn);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const transactionStatus = useSelector(selectUploadRenewalPaymentData);
  const dateValue = transactionStatus && transactionStatus?.responseTime;
  const formattedDate = dayjs(dateValue).format('DD/MM/YYYY');

  const location = useLocation();
  const policyDatas = location?.state?.paymentData?.policyData;

  function generatePDF() {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4',
    });
    doc.addFont('ArialMS', 'Arial', 'normal');
    doc.setFont('ArialMS');
    doc.text('CAMS Insurance Repository Services Ltd', 265, 40);
    doc.text('New No.10, Old No.178, MGR Salai,,', 265, 60);
    doc.text('Nungambakkam,Opp.Hotel Palmgrove,', 265, 82);
    doc.text('Chennai 600 034,', 265, 105);
    doc.text('Tamil Nadu', 265, 125);
    const html = `<!DOCTYPE html>
<html>
<head>
<style>

.container {
      width: 100%;
    }
    </style>
</head>
  <body>
    <div style="margin-top:40px">
      <div
        style="
          display: flex;
          align-items:center;
          width:500px;"
      >
        <div style="margin-right:70px">
         <image
       src="${BemaCentral}"
        style="
          width: 150px;
          height: 30px;"
      />
        </div>
        <div>
          <div>
           
          </div>
          <div>
            <p style="font-size: 15px;letter-spacing:0px;word-spacing:0px;line-height:12px;text-align:right"></p>
          </div>
          <div>
            <p style="font-size: 15px;letter-spacing:0px;word-spacing:0px;line-height:12px;text-align:right"></p>
          </div>
          <div>
            <p style="font-size: 15px;letter-spacing:0px;word-spacing:0px;line-height:12px;text-align:right"></p>
          </div>
          <div>
            <p style="font-size: 15px;letter-spacing:0px;word-spacing:0px;line-height:12px;text-align:right"></p>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center ;letter-spacing:0px;width:500px;margin-top:70px">
      <h1 style="font-size: 18px">Acknowledgement Receipt</h1>
    </div>
    <div style="display: flex;flex-direction:column;width:500px">
    <div>
    <div style="display: flex; justify-content: space-between;flex-direction:row;width:500px">
      <div style="display: flex; flex-direction: row">
        <div>
          <p style="font-size: 14px;letter-spacing:0px;">Receipt No:</p>
        </div>
        <div>
          <p style="font-size: 14px;letter-spacing:0px;word-wrap: break-word;width:200px;margin-left:10px;">${transactionId}</p>
        </div>
      </div>
      <div style="display: flex; flex-direction: row">
        <div>
          <p style="font-size: 14px;letter-spacing:0px">Receipt Date:</p>
        </div>
        <div>
          <p style="font-size: 14px;letter-spacing:0px;margin-left:10px;">${formattedDate}</p>
        </div>
      </div>
    </div>
    </div>
    </div>
    <hr style="width:500px"/>
    <div style="text-align: center;width:500px">
      <h1 style="letter-spacing:0px;font-size: 18px">Transaction Details</h1>
    </div>
    <hr style="width:500px"/>
    <div style="width:500px">
      <div style="display: flex; flex-direction: row; justify-content: space-between;width:500px">
        <div style="width:160px">
          <p style="text-align:left;letter-spacing:0px;">Order ID</p>
        </div>
        <div style="width:60px">
          <p style="text-align: center;">:</p>
        </div>
        <div style="width:150px">
          <p style="letter-spacing:0px;text-align:left">
          ${type === 'renewalPayment' && state?.rzpOrderId
        ? state?.rzpOrderId
        : ''
      }
          ${type === 'Payment'
        ? policyDatas?.orderId
          ? policyDatas?.orderId
          : location?.state?.paymentResponse?.order_id
            ? location?.state?.paymentResponse?.order_id
            : ''
        : ''
      }
          
       
       
          </p>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <div style="width:160px">
          <p style="text-align: left;letter-spacing:0px">Payment ID</p>
        </div>
        <div style="width:60px">
          <p style="text-align: center;">:</p>
        </div>
        <div style="width:150px">
          <p style="letter-spacing:0px;text-align:left; word-wrap: break-word;">${paymentResponse?.razorpay_payment_id}</p>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-between">
        <div style="width:160px">
          <p style="text-align: left;letter-spacing:0px">Transaction Status</p>
        </div>
        <div style="width:60px">
          <p style="text-align: center;">:</p>
        </div>
        <div style="width:150px">
          <p style="letter-spacing:0px;text-align:left" >
     

          
             ${type === 'renewalPayment' && transactionStatus?.status
        ? transactionStatus?.status
        : ''
      }
          ${type === 'Payment'
        ? policyDatas?.trxnStatus
          ? policyDatas?.trxnStatus
          : location?.state?.status
            ? location?.state?.status
            : ''
        : ''
      }
          </p>
        </div>
      </div>
    </div>
    <hr style="width:500px"/>
    <div style="text-align: center; font-size: 12px;letter-spacing:0px;width:500px">
      <h1 style="letter-spacing:0px;font-size: 18px">Customer Details</h1>
    </div>
    <hr style="width:500px"/>
    <div style="width:500px">
      <div style="display: flex; flex-direction: row; justify-content: space-between;width:500px">
        <div style="width:160px">
          <p style="letter-spacing:0px">Name</p>
        </div>
        <div style="width:60px">
          <p style="text-align: center;">:</p>
        </div>
        <div style="width:150px">
          <p style="letter-spacing:0px;text-align:left">
  
         
              ${type === 'renewalPayment' && state?.customerName
        ? state?.customerName
        : ''
      }
          ${type === 'Payment' && policyDatas?.customerName
        ? policyDatas?.customerName
        : ''
      }
            </p>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-between">
        <div style="width:160px">
          <p style="letter-spacing:0px">eIA number</p>
        </div>
        <div style="width:60px">
          <p style="text-align: center;">:</p>
        </div>
        <div style="width:150px">
          <p style="letter-spacing:0px;text-align:left">
            ${loggedUser?.eiaNo ? loggedUser?.eiaNo : '-'}
            </p>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-between">
        <div style="width:160px">
          <p style="letter-spacing:0px">Mobile</p>
        </div>
        <div style="width:60px">
          <p style="text-align: center;">:</p>
        </div>
        <div style="width:150px">
          <p style="letter-spacing:0px;text-align:left">
         
                 ${type === 'renewalPayment' && state?.mobile
        ? state?.mobile
        : ''
      }
          ${type === 'Payment'
        ? policyDatas?.mobileNo
          ? policyDatas?.mobileNo
          : location?.state?.paymentData?.prefill?.contact
            ? location?.state?.paymentData?.prefill?.contact
            : ''
        : ''
      }
             </p>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-between">
        <div style="width:160px">
          <p style="letter-spacing:0px">Email ID</p>
        </div>
        <div style="width:60px">
          <p style="text-align: center;">:</p>
        </div>
        <div style="width:150px">
          <p style="letter-spacing:0px;text-align:left"> 
        
                  ${type === 'renewalPayment' && state?.email
        ? state?.email
        : ''
      }
          ${type === 'Payment'
        ? policyDatas?.email
          ? policyDatas?.email
          : location?.state?.paymentData?.prefill?.email
            ? location?.state?.paymentData?.prefill?.email
            : ''
        : ''
      }</p>
        </div>
      </div>
    </div>
     <hr style="width:500px;margin-top:80px"/>
    <div style="text-align: center; font-size: 12px;letter-spacing:0px;width:500px;">
      <h1 style="letter-spacing:0px;font-size: 18px">Payment Details</h1>
    </div>
    <hr style="width:500px"/>
    <div style="width:500px">
      <div style="display: flex; flex-direction: row; justify-content: space-between;width:500px">
        <div style="width:160px">
          <p style="letter-spacing:0px">Policy Number</p>
        </div>
        <div style="width:60px">
          <p style="text-align: center;">:</p>
        </div>
        <div style="width:150px">
          <p style="letter-spacing:0px;text-align:left">  
          
              
              
                 ${type === 'renewalPayment' && state?.previousPolicyNo
        ? state?.previousPolicyNo
        : ''
      }
          ${type === 'Payment' && policyDatas?.policyNo
        ? policyDatas?.policyNo
        : ''
      }
          </p>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-between">
        <div style="width:160px">
          <p style="letter-spacing:0px">Insurance company</p>
        </div>
        <div style="width:60px">
          <p style="text-align: center;">:</p>
        </div>
        <div style="width:150px">
          <p style="letter-spacing:0px;text-align:left"> 
            
                 
                 ${type === 'renewalPayment' && state?.insurerCompanyName
        ? state?.insurerCompanyName
        : ''
      }
          ${type === 'Payment' && policyDatas?.companyName
        ? policyDatas?.companyName
        : ''
      }
          </p>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-between">
        <div style="width:160px">
          <p style="letter-spacing:0px">Payment Date</p>
        </div>
        <div style="width:60px">
          <p style="text-align: center;">:</p>
        </div>
        <div style="width:150px">
          <p style="letter-spacing:0px;text-align:left">
      
             
                      
                 ${type === 'renewalPayment' && formattedDate
        ? formattedDate
        : ''
      }
          ${type === 'Payment' && policyDatas?.paymentDate
        ? policyDatas?.paymentDate
        : ''
      }
          </p>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-between">
        <div style="width:160px">
          <p style="letter-spacing:0px">Amount Paid</p>
        </div>
        <div style="width:60px">
          <p style="text-align: center;">:</p>
        </div>
        <div style="width:150px">
          <p style="letter-spacing:0px;text-align:left">Rs. ${amount || '-'}</p>
        </div>
      </div>
    </div>
    <hr style="width:500px"/>
    <div style="width:500px">
      <div style="font-size: 10px;style="letter-spacing:0px;width:500px;text-align:center;">
        <i style="width:500px">
          Disclaimer:This is a system generated receipt. Please do not hesitate
          to email us at info@camsrepository.com or call us at 1800 200 7737
          (Toll Free) for any assistance or clarification. We will be glad to
          assist you
        </i>
      </div>
    </div>
  </body>
</html>
`;
    doc.html(html, {
      callback(pdf: any) {
        pdf.save('PaymentReceipt.pdf');
        var base = pdf.output('datauristring');
        dispatch(openPdf({ pdfUrl: base.split('base64,')[1] }));
        const alertInfo: any = {
          open: true,
          title: 'Download Success!',
          type: 'SUCCESS',
          description: `Document has downloaded with file name as PaymentReceipt.pdf In downloads directory.`,
        };

        dispatch(setAlertInfo(alertInfo));
      },
      x: 50,
    });
  }
  const theme = useTheme();
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} className="payment-card-success mt-30">
          <Grid container justifyContent="center">
            <Grid item style={{ position: 'relative' }}>
              <SvgConverter
                Icon={GreenTick}
                className="payment-success-failed-icon-position"
              />
              <SvgConverter Icon={Circle} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        textAlign="center"
        className="mt-10"
      >
        <Grid item>
          <LabelWithIcon
            svgClassName=""
            justifyContent="center"
            Icon={OrangeRupee}
            label={convertAmount(amount)}
            labelProps={{
              color: theme.palette.common.black,
              fontSize: 20,
              fontWeight: 600,
            }}
          />
          <Typography fontSize={18} fontWeight={400} color=" #63a103">
            {t('PREMIUM_PAYMENT_SUCCESSFULL')}
          </Typography>
          <Typography
            fontSize={14}
            color={theme.palette.common.black}
            className="mt-15"
          >
            {t('TRANSACTION_ID')}{' '}
          </Typography>{' '}
          <Typography
            fontSize={14}
            fontWeight={600}
            color={theme.palette.common.black}
            className="mb-20"
          >
            {transactionId}
          </Typography>
          <Grid item className="mb-10">
            <Separator color=" #d7d7d7" />
          </Grid>
          <CustomButton
            className="fw-600 f-16"
            text={t('DOWNLOAND_PAYMENT_ACKNOWLEDGMENT')}
            variant="text"
            color="primary"
            onClick={() => generatePDF()}
          />{' '}
          <Grid item className="mt-10 mb-20">
            <Separator color=" #d7d7d7" />
          </Grid>
          <Typography
            fontSize={16}
            color={theme.palette.primary.main}
            lineHeight={1.3}
            className="mb-20"
          >
            {t('The')}{' '}
            <Typography component="span" fontWeight="600" lineHeight={1}>
              {t('PREMIUM_RECIEPT')}
            </Typography>{' '}
            {t('WILL_BE_GENERATED')}{' '}
            {/* <Typography component="span" fontWeight="600" lineHeight={1}>
							{transactionStatus?.premiumReceiptGenerate}
						</Typography>{' '}
						{t('AVAILABLE_ON_BIMA_CENTRAL')} */}
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid container justifyContent="right" textAlign="right">
        <Grid item justifyContent="right" textAlign="right">
          <Link to="/" replace>
            <CustomButton
              className=" fw-600"
              text={t('CLICK__TO_GO_BACK')}
              variant="text"
              color="primary"
              showIcon={false}
            />
          </Link>
        </Grid>
      </Grid> */}
      {/* <Grid container justifyContent="left" textAlign="left" className="mt-5">
				<Grid item>
					<Typography fontSize={12} color={theme.palette.primary.main}>
						{t('TERMS_&_CONDITIONS')}
					</Typography>
					<Typography fontSize={12} fontWeight={500} color="#7f7f7f">
						{t('LOREM_IPSUM')}.
					</Typography>
				</Grid>
			</Grid> */}
      <ApiModal />
      <PDFModal />
    </>
  );
};

export default PaymentSuccess;
