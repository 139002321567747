import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { convertAmount, getFormatDate } from '../../../../../../common/utils';

interface BenefitProps {
	benefitDetails?: any;
}
const Benefit: React.FC<BenefitProps> = ({ benefitDetails }) => {
	const formattedBenefitStructureDate = getFormatDate(
		benefitDetails && benefitDetails?.benefitStructureDate
	);
	const { t } = useTranslation();
	return (
		<Grid container>
			<Grid item xs={11} className="py-15">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('BENEFIT')}
				</Typography>
			</Grid>
			{benefitDetails && benefitDetails?.length > 0
				? benefitDetails.map((mapdata: any, index: any) => (
						<Grid item container xs={12} key={index} className="mb-20">
							<Grid item xs={0.5}>
								<Typography
									color={theme.palette.common.black}
									fontSize={16}
									fontWeight={600}
								>
									{index + 1}.
								</Typography>
							</Grid>
							<Grid item container xs={10} className="blue-box  ">
								<Grid item container xs={12} className="ml-10 mr-2 py-10">
									<Grid item xs={12}>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('BENEFIT_STRUCTURE_DATE')}
										</Typography>
										<Typography
											color={theme.palette.common.black}
											fontSize={18}
											className="mb-10"
										>
											{formattedBenefitStructureDate === 'Invalid Date'
												? '-'
												: formattedBenefitStructureDate}
										</Typography>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('BENEFIT_STRUCTURE_%_AGE')}
										</Typography>
										<Typography
											color={theme.palette.common.black}
											fontSize={18}
											className="mb-10"
										>
											{mapdata?.benefitStructurePercentage
												? mapdata?.benefitStructurePercentage
												: '-'}
											%
										</Typography>{' '}
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('BENEFIT_STRUCTURE_AMOUNT')}
										</Typography>
										<LabelWithIcon
											svgClassName="mb-2"
											justifyContent="flex-start"
											Icon={OrangeRuppee}
											label={convertAmount(mapdata?.benefitStructureAmount)}
											labelProps={{
												color: theme.palette.common.black,
												fontSize: 18,
												fontWeight: 600,
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
				  ))
				: '-'}
		</Grid>
	);
};

export default Benefit;
