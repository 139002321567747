import React, { useEffect, useRef, useState } from 'react';
import { Divider, Grid, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import LinearBar from './ProgressBar/LinearBar';
import { ReactComponent as RefreshIcon } from '../icons/dashboard/circle_arrow-icon.svg';
import { ReactComponent as OrangeArrow } from '../icons/orange-arrow.svg';
import PolicyCardCost from '../../features/App/DashboardScreen/common/PolicyCardCost';
import SvgConverter from '../SvgConverter';
import { convertAmount } from '../utils';
import theme from '../theme';
import CurrentValueCard from '../../features/App/DashboardScreen/common/CurrentValueCard';
import { dashboardListRequest } from '../../features/App/DashboardScreen/Dashboard.slice';

const LifeProgressBarNon = ({
	progressVal,
	total,
	updatedDate,
	currentValue,
	allValueText,
}: any) => {
	const dispatch = useDispatch();
	const firstCardRef = useRef<any>(null);
	const clampProgress = Math.max(0, Math.min(1, progressVal));
	const [isFirstRender, setIsFirstRender] = useState(true);
	const onClickRefresh = () => {
		dispatch(dashboardListRequest({}));
	};
	useEffect(() => {
		setIsFirstRender(false);
	}, []);
	return (
		<div className="" style={{ position: 'relative' }}>
			<div style={{ transform: 'rotateY(180deg)', display: 'flex' }}>
				{!isFirstRender && (
					<LinearBar progressVal={progressVal} total={total} />
				)}
			</div>
			<Grid
				container
				flexWrap="nowrap"
				className="h-140 w-0"
				style={{
					position: 'absolute',
					right: `${clampProgress * 100}%`,
					marginRight:
						clampProgress * 100 > 50
							? (firstCardRef?.current?.clientWidth ?? 0) + 5
							: (firstCardRef?.current?.clientWidth ?? 0) + 122,

					transition: 'all .6s',
				}}
				justifyContent="start"
				// flexDirection={progressVal > 50 ? 'row-reverse' : 'row'}
				alignItems="stretch"
			>
				{clampProgress * 100 > 50 ? (
					<>
						<Grid item ref={firstCardRef} className="mt-35">
							<IconButton className="p-0" onClick={onClickRefresh}>
								<SvgConverter Icon={RefreshIcon} />
							</IconButton>
						</Grid>
						<Grid
							container
							alignItems="center"
							className="mx-5"
							justifyContent="space-around"
						>
							<Grid item>
								<SvgConverter Icon={OrangeArrow} />
							</Grid>
							<Grid item>
								<Divider
									orientation="vertical"
									sx={{
										height: '90px', // Set the height as per your requirement
										backgroundColor: '#d7d7d7', // Optional: Set the background color
										width: '1px',
									}}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12} container>
							<PolicyCardCost.NormalNonGuaranteed
								titleFontWeight={600}
								color={theme.palette.primary.dark}
								title="CURRENT_VALUE"
								value={currentValue ? convertAmount(currentValue) : '0'}
								policyCounts={3}
								hideLastUpdate={false}
								hidePolicyCounts
								allValueText={allValueText}
								updatedDate={updatedDate}
							/>
						</Grid>
					</>
				) : (
					<>
						<Grid item>
							<CurrentValueCard
								titleFontWeight={600}
								color={theme.palette.primary.dark}
								title="CURRENT_VALUE"
								value={currentValue ? convertAmount(currentValue) : '-'}
								policyCounts={3}
								hideLastUpdate={false}
								hidePolicyCounts
								allValueText={allValueText}
								updatedDate={updatedDate}
							/>
						</Grid>
						<Grid
							container
							alignItems="center"
							className="mx-5 mr-10"
							justifyContent="space-around"
						>
							<Grid item>
								<SvgConverter Icon={OrangeArrow} />
							</Grid>
							<Grid item>
								<Divider
									orientation="vertical"
									sx={{
										height: '90px',
										backgroundColor: '#d7d7d7',
										width: '1px',
									}}
								/>
							</Grid>
						</Grid>
						<Grid item ref={firstCardRef} className="mt-35">
							<IconButton className="p-0" onClick={onClickRefresh}>
								<SvgConverter Icon={RefreshIcon} />
							</IconButton>
						</Grid>
					</>
				)}

				<Grid item />
			</Grid>
		</div>
	);
};

export default LifeProgressBarNon;
