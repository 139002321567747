import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import Separator from '../../../../../../common/ui/Separator';
import SvgConverter from '../../../../../../common/SvgConverter';
import { ReactComponent as Plus } from '../../../../../../common/icons/plus-bold.svg';
import { convertAmount } from '../../../../../../common/utils';

interface RidersProps {
	riderDetails?: any;
}
const Riders: React.FC<RidersProps> = ({ riderDetails }) => {
	const { t } = useTranslation();
	return (
		<Grid container>
			<Grid item xs={12} className="py-15">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('RIDERS')}
				</Typography>
			</Grid>
			{riderDetails?.length !== 0
				? riderDetails?.map((mapdata: any, index: any) => (
						<Grid item container xs={12} key={index} className="mb-30">
							<Grid item xs={0.5}>
								<Typography color={theme.palette.common.black} fontSize={18}>
									{index + 1}.
								</Typography>
							</Grid>
							<Grid item container xs={10} className="blue-box  ml-8 ">
								<Grid item xs={12} className="ml-10 mr-2 mt-10 ">
									<Typography
										color={theme.palette.primary.main}
										fontSize={18}
										fontWeight={600}
									>
										{mapdata?.riderName?.length === 0
											? '-'
											: mapdata?.riderName}
									</Typography>
								</Grid>
								<Grid item xs={12} className="ml-10 mr-2">
									<Typography
										color={theme.palette.common.black}
										fontSize={14}
										fontWeight={600}
									>
										{mapdata?.riderCode ? mapdata?.riderCode : '-'}
									</Typography>
									<Typography color={theme.palette.common.black} fontSize={14}>
										{t('IRDAI')}: 12N231234
									</Typography>
									<Typography color={theme.palette.common.black} fontSize={14}>
										{t('COMPANY')}: 123423WEDRSF
									</Typography>
									<Typography
										color={theme.palette.common.black}
										fontSize={14}
										textAlign="left"
									>
										{mapdata?.riderDescription
											? mapdata?.riderDescription
											: '-'}
									</Typography>
								</Grid>
								<Separator color={theme.palette.grey[400]} />
								<Grid item container xs={12} className="ml-10 py-20">
									<Grid item xs={6}>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('SUM_ASSURED')}
										</Typography>
										<LabelWithIcon
											svgClassName="mb-2"
											justifyContent="flex-start"
											Icon={OrangeRuppee}
											label={mapdata?.sumAssured ? mapdata?.sumAssured : '-'}
											labelProps={{
												color: theme.palette.common.black,
												fontSize: 18,
												fontWeight: 600,
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('COVER_TERM')}
										</Typography>
										<Typography
											color={theme.palette.common.black}
											fontSize={18}
										>
											{mapdata?.coverTerm ? mapdata?.coverTerm : '-'}
											&nbsp; {t('YEARS')}
										</Typography>
									</Grid>
								</Grid>
								<Separator color={theme.palette.grey[400]} />
								<Grid item container xs={12} className="ml-10">
									<Grid item xs={12} className="py-5">
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('RIDER_PREMIUM_PAYMENT_TERM')}
										</Typography>
										<Typography
											color={theme.palette.common.black}
											fontSize={18}
										>
											{mapdata?.riderPremiumPaymentTerm
												? mapdata?.riderPremiumPaymentTerm
												: '-'}
											&nbsp; {t('YEARS')}
										</Typography>
									</Grid>
									<Grid item xs={12} className="py-5">
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('TOTAL_RIDER_PREMIUM')}
										</Typography>

										<LabelWithIcon
											svgClassName="mb-2"
											justifyContent="flex-start"
											Icon={OrangeRuppee}
											label={
												mapdata?.totalRiderPremium
													? convertAmount(mapdata?.totalRiderPremium)
													: '0'
											}
											labelProps={{
												color: theme.palette.common.black,
												fontSize: 18,
												fontWeight: 600,
											}}
										/>
									</Grid>
									<Grid
										item
										container
										xs={11}
										className="widget-title widgettitle mb-15"
									>
										<Grid item container xs={12}>
											<Grid item xs={5}>
												<Grid item xs={12}>
													<Typography
														color={theme.palette.common.black}
														fontSize={14}
													>
														{t('BASE_PREMIUM')}
													</Typography>
													<LabelWithIcon
														svgClassName="mb-2"
														justifyContent="flex-start"
														Icon={OrangeRuppee}
														label={
															mapdata?.basePremium
																? convertAmount(mapdata?.basePremium)
																: '0'
														}
														labelProps={{
															color: theme.palette.common.black,
															fontSize: 18,
															fontWeight: 600,
														}}
													/>
												</Grid>
											</Grid>
											<Grid item xs={1}>
												<SvgConverter Icon={Plus} className=" mt-5 mr-20" />
											</Grid>
											<Grid item xs={5}>
												<Typography
													color={theme.palette.common.black}
													fontSize={14}
													className="loaded"
												>
													{t('LOADED_PREMIUM')}
												</Typography>
												<LabelWithIcon
													svgClassName="mb-2"
													justifyContent="flex-start"
													Icon={OrangeRuppee}
													label={
														mapdata?.loadedPremium
															? convertAmount(mapdata?.loadedPremium)
															: '0'
													}
													labelProps={{
														color: theme.palette.common.black,
														fontSize: 18,
														fontWeight: 600,
													}}
												/>
											</Grid>
										</Grid>
										<Grid item container xs={12} className="mt-10">
											<Grid item xs={2}>
												<Typography
													color={theme.palette.common.black}
													fontSize={34}
													fontWeight={600}
												>
													+
												</Typography>
											</Grid>
											<Grid item xs={9}>
												<Typography
													color={theme.palette.common.black}
													fontSize={14}
													className="text-wrap"
												>
													{t('TAXES')}
												</Typography>
												<LabelWithIcon
													svgClassName="mb-2"
													justifyContent="flex-start"
													Icon={OrangeRuppee}
													label={
														mapdata?.taxes ? convertAmount(mapdata?.taxes) : '0'
													}
													labelProps={{
														color: theme.palette.common.black,
														fontSize: 18,
														fontWeight: 600,
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
				  ))
				: '-'}
		</Grid>
	);
};

export default Riders;
