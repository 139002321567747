/* eslint-disable no-nested-ternary */
import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import Separator from '../../../../../../common/ui/Separator';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import { convertAmount, getFormatDate } from '../../../../../../common/utils';

interface ClaimHistoryProps {
	claimHistoryPropsDetails?: any;
}
const ClaimHistory: React.FC<ClaimHistoryProps> = ({
	claimHistoryPropsDetails,
}) => {
	const { t } = useTranslation();

	return (
		<>
			{claimHistoryPropsDetails &&
			claimHistoryPropsDetails?.length !== 0 &&
			(claimHistoryPropsDetails?.[0]?.admittedClaimAmount != '' ||
				claimHistoryPropsDetails?.[0]?.approvedClaimSettlementAmount != '' ||
				claimHistoryPropsDetails?.[0]?.claim != '' ||
				claimHistoryPropsDetails?.[0]?.claimStatus != '' ||
				claimHistoryPropsDetails?.[0]?.cliamIntimation != '' ||
				claimHistoryPropsDetails?.[0]?.dateOfApproval != '' ||
				claimHistoryPropsDetails?.[0]?.dateOfPayement != '' ||
				claimHistoryPropsDetails?.[0]?.grossClaimAmount != '' ||
				claimHistoryPropsDetails?.[0]?.intimationDate != '' ||
				claimHistoryPropsDetails?.[0]?.mode != '' ||
				claimHistoryPropsDetails?.[0]?.netAmountPaid != '' ||
				claimHistoryPropsDetails?.[0]?.paymentReference != '' ||
				claimHistoryPropsDetails?.[0]?.preAuthorizationAmount != '' ||
				claimHistoryPropsDetails?.[0]?.preAuthorizationDate != '' ||
				claimHistoryPropsDetails?.[0]?.typeOfClaim != '') &&
			claimHistoryPropsDetails[0] ? (
				<Grid container className="py-10">
					<Grid item xs={12} className="mb-10">
						<Typography
							color={theme.palette.primary.main}
							fontSize={14}
							fontWeight={600}
						>
							{t('CLAIM_HISTORY')}
						</Typography>
					</Grid>
					{claimHistoryPropsDetails &&
						claimHistoryPropsDetails.map((mapdata: any, index: any) => (
							<Grid item container xs={12} className="mb-20">
								<Grid item xs={0.5}>
									<Typography color={theme.palette.common.black} fontSize={18}>
										{index + 1}.
									</Typography>
								</Grid>
								<Grid item container xs={11}>
									<Grid item container xs={10} className="blue-box ">
										<Grid item xs={6} className="px-8">
											<Typography
												color={theme.palette.primary.main}
												fontSize={14}
												fontWeight={600}
												className="mt-10 "
											>
												{t('CLAIM_INTIMATION')} #
											</Typography>
											<Typography
												color={theme.palette.common.black}
												fontSize={16}
												fontWeight={600}
											>
												{mapdata.cliamIntimation === ' '
													? '-'
													: mapdata.cliamIntimation}
											</Typography>
										</Grid>
										<Grid item xs={6} className="px-8">
											<Typography
												color={theme.palette.primary.main}
												fontSize={14}
												fontWeight={600}
												className="mt-10"
											>
												{t('INTIMATION_DATE')}
											</Typography>
											<Typography
												color={theme.palette.common.black}
												fontSize={16}
											>
												{getFormatDate(mapdata && mapdata?.intimationDate)}
											</Typography>
										</Grid>
										<Grid item xs={12} className="px-8">
											<Typography
												color={theme.palette.primary.main}
												fontSize={14}
												fontWeight={600}
												className="mt-15"
											>
												{t('ADMITTED_CLAIM_AMOUNT')}
											</Typography>
											<LabelWithIcon
												className=""
												svgClassName="mb-2 "
												justifyContent="flex-start"
												Icon={OrangeRuppee}
												label={
													mapdata.admittedClaimAmount?.length === 0
														? '0'
														: convertAmount(mapdata.admittedClaimAmount)
												}
												labelProps={{
													color: theme.palette.common.black,
													fontSize: 18,
													fontWeight: 600,
												}}
											/>
										</Grid>{' '}
										<Grid item xs={12} className="px-8">
											<Typography
												color={theme.palette.primary.main}
												fontSize={14}
												fontWeight={600}
												className="mt-15"
											>
												{t('TYPE_OF_CLAIM')}
											</Typography>
											{/* <Typography
												color={theme.palette.common.black}
												fontSize={16}
											>
												{mapdata.typeOfClaim?.length === 0
													? '-'
													: mapdata.typeOfClaim}
											</Typography> */}
											{mapdata && mapdata?.typeOfClaim === '0' ? (
												<Typography
													color={theme.palette.common.black}
													fontSize={14}
												>
													{t('CASHLESS')}
												</Typography>
											) : (
												<Typography
													color={theme.palette.common.black}
													fontSize={14}
												>
													{t('REIMBURSEMENT')}
												</Typography>
											)}
										</Grid>{' '}
										<Grid item xs={12} className="py-10 ">
											<Separator color={theme.palette.grey[400]} />
										</Grid>
										<Grid item container xs={12} className="px-8">
											<Grid item xs={12}>
												<Typography
													color={theme.palette.primary.main}
													fontSize={14}
													fontWeight={600}
												>
													{t('PRE_AUTHORIZATION_AMOUNT')}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<LabelWithIcon
													className=""
													svgClassName="mb-2 "
													justifyContent="flex-start"
													Icon={OrangeRuppee}
													label={
														mapdata.preAuthorizationAmount?.length === 0
															? '-'
															: mapdata.preAuthorizationAmount
													}
													labelProps={{
														color: theme.palette.common.black,
														fontSize: 18,
														fontWeight: 600,
													}}
												/>
											</Grid>
											<Grid item xs={12}>
												<Typography
													color={theme.palette.primary.main}
													fontSize={14}
													fontWeight={600}
												>
													{t('PRE_AUTHORIZATION_DATE')}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Typography
													color={theme.palette.common.black}
													fontSize={16}
												>
													{getFormatDate(
														mapdata &&
															mapdata?.preAuthorizationDate?.length === 0
															? '-'
															: mapdata?.preAuthorizationDate,
														'YYYY-MM-DD'
													)}
												</Typography>
											</Grid>
										</Grid>
										<Grid item xs={12} className="py-10 ">
											<Separator color={theme.palette.grey[400]} />
										</Grid>
										<Grid item container xs={12} className="px-8">
											<Grid item xs={12} className="py-10">
												<Typography
													color={theme.palette.primary.main}
													fontSize={14}
													fontWeight={600}
												>
													{t('APPROVED_CLAIM_SETTLEMENT_AMOUNT')}
												</Typography>
												<LabelWithIcon
													className=""
													svgClassName="mb-2 "
													justifyContent="flex-start"
													Icon={OrangeRuppee}
													label={
														mapdata.approvedClaimSettlementAmount?.length === 0
															? '-'
															: mapdata.approvedClaimSettlementAmount
													}
													labelProps={{
														color: theme.palette.common.black,
														fontSize: 18,
														fontWeight: 600,
													}}
												/>
											</Grid>
											<Grid item xs={6} className="py-5">
												<Typography
													color={theme.palette.primary.main}
													fontSize={14}
													fontWeight={600}
												>
													{t('CLAIM_STATUS')}
												</Typography>
												<Typography
													color={theme.palette.common.black}
													fontSize={16}
												>
													{mapdata.claimStatus?.length === 0
														? '-'
														: mapdata.claimStatus}
												</Typography>
											</Grid>{' '}
											<Grid item xs={6} className="py-5">
												<Typography
													color={theme.palette.primary.main}
													fontSize={14}
													fontWeight={600}
												>
													{t('CLAIM')} #
												</Typography>
												<Typography
													color={theme.palette.common.black}
													fontSize={16}
												>
													{mapdata.claim?.length === 0 ? '0' : mapdata.claim}
												</Typography>
											</Grid>
											<Grid item xs={12} className="py-10">
												<Typography
													color={theme.palette.primary.main}
													fontSize={14}
													fontWeight={600}
												>
													{t('DATE_OF_APPROVAL')}
												</Typography>
												<Typography
													color={theme.palette.common.black}
													fontSize={16}
												>
													{getFormatDate(mapdata && mapdata?.dateOfApproval)}
												</Typography>
											</Grid>
										</Grid>{' '}
										<Grid item xs={12} className="py-10 ">
											<Separator color={theme.palette.grey[400]} />
										</Grid>
										<Grid item container xs={12}>
											<Grid item xs={12} className="px-8">
												<Typography
													color={theme.palette.primary.main}
													fontSize={14}
													fontWeight={600}
													className="mt-5"
												>
													{t('GROSS_CLAIM_AMOUNT')}
												</Typography>
												<LabelWithIcon
													className=""
													svgClassName="mb-2 "
													justifyContent="flex-start"
													Icon={OrangeRuppee}
													label={
														mapdata.grossClaimAmount?.length === 0
															? '-'
															: mapdata.grossClaimAmount
													}
													labelProps={{
														color: theme.palette.common.black,
														fontSize: 18,
														fontWeight: 600,
													}}
												/>
											</Grid>{' '}
											<Grid item xs={12} className="px-8">
												<Typography
													color={theme.palette.primary.main}
													fontSize={14}
													fontWeight={600}
													className="mt-5"
												>
													{t('NET_AMOUNT_PAID')}
												</Typography>
												<LabelWithIcon
													className=""
													svgClassName="mb-2 "
													justifyContent="flex-start"
													Icon={OrangeRuppee}
													label={
														mapdata.netAmountPaid?.length === 0
															? '-'
															: mapdata.netAmountPaid
													}
													labelProps={{
														color: theme.palette.common.black,
														fontSize: 18,
														fontWeight: 600,
													}}
												/>
												<Typography
													color={theme.palette.common.black}
													fontSize={14}
													className="mt-5"
												>
													{`[${t('GROSS_AMOUNT')} +${t(
														'LOSS_ADJUSTMENT_EXPENSES'
													)} +${t('TP_INTEREST_AMOUNT')} -${t(
														'RECOVERABLES'
													)} -${t('DEDUCTIBLES')} 
											
											]`}
												</Typography>
											</Grid>
										</Grid>
										<Grid item container xs={12} className="px-8 mb-10">
											<Grid item xs={6}>
												<Typography
													color={theme.palette.primary.main}
													fontSize={14}
													fontWeight={600}
													className="mt-15"
												>
													{t('DATE_OF_PAYMENT')}
												</Typography>
												<Typography
													color={theme.palette.common.black}
													fontSize={16}
												>
													{getFormatDate(mapdata && mapdata?.dateOfPayement) ||
														'-'}
												</Typography>
											</Grid>{' '}
											<Grid item xs={6}>
												<Typography
													color={theme.palette.primary.main}
													fontSize={14}
													fontWeight={600}
													className="mt-15"
												>
													{t('MODE')}
												</Typography>
												{/* <Typography
													color={theme.palette.common.black}
													fontSize={16}
												>
													{mapdata.mode?.length === 0 ? '-' : mapdata.mode}
												</Typography> */}

												<>
													{mapdata && mapdata?.mode === '1' ? (
														<Typography
															color={theme.palette.common.black}
															fontSize={14}
															fontWeight={600}
														>
															{t('CHEQUE')}
														</Typography>
													) : mapdata && mapdata?.mode === '2' ? (
														<Typography
															color={theme.palette.common.black}
															fontSize={14}
															fontWeight={600}
														>
															{t('ONLINE')}
														</Typography>
													) : mapdata && mapdata?.gender === '3' ? (
														<Typography
															color={theme.palette.common.black}
															fontSize={14}
															fontWeight={600}
														>
															{t('DD')}
														</Typography>
													) : mapdata && mapdata?.gender === '4' ? (
														<Typography
															color={theme.palette.common.black}
															fontSize={14}
															fontWeight={600}
														>
															{t('FDR')}
														</Typography>
													) : (
														'-'
													)}
												</>
											</Grid>
											<Grid item xs={12}>
												<Typography
													color={theme.palette.primary.main}
													fontSize={14}
													fontWeight={600}
													className="mt-15"
												>
													{t('PAYMENT_REFERENCE')} #
												</Typography>
												<Typography
													color={theme.palette.common.black}
													fontSize={16}
												>
													{mapdata.paymentReference?.length === 0
														? '-'
														: mapdata.paymentReference}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						))}
				</Grid>
			) : (
				<Grid item xs={12} className="mb-10 mt-20">
					<Grid item xs={12} className="mb-10">
						<Typography
							color={theme.palette.primary.main}
							fontSize={14}
							fontWeight={600}
						>
							{t('CLAIM_HISTORY')}
						</Typography>
					</Grid>
					<Typography color="black" fontSize={14} fontWeight={600}>
						{t('NO_CLAIMS')}
					</Typography>
				</Grid>
			)}
		</>
	);
};
export default ClaimHistory;
