import { call, put, takeEvery } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';
import {
	getRazorPayOrderId,
	uploadPaymentDataEffect,
	policyRenewal,
} from './Payment.services';
import {
	fetchPaymentDetailsRequest,
	paymentDetailsError,
	paymentDetailsSuccess,
	uploadPaymentDetailsError,
	uploadPaymentDetailsRequest,
	uploadPaymentDetailsSuccess,
	policyRenewalRequest,
	policyRenewalError,
	policyRenewalSuccess,
	uploadRenewalPaymentDetailsRequest,
	uploadRenewalPaymentDetailsSuccess,
	uploadRenewalPaymentDetailsError,
} from './Payment.slice';
import { setAlertInfo } from '../Common/Common.slice';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';

function* paymentDetailsWatcher({ payload, t }: any) {
	try {
		const { data } = yield call(getRazorPayOrderId, payload);
		if (data.policyStatus !== 'ACCEPT') {
			yield put(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: data?.ErrorMsg ? data?.ErrorMsg : t('UNKNOWN_ERROR'),
				})
			);
			throw new Error(data);
		}
		yield put(paymentDetailsSuccess(data));
	} catch (e: any) {
		yield put(paymentDetailsError(e?.ErrorMsg));
	}
}

function* uploadPaymentDataWatcher({ payload }: any) {
	try {
		const { data } = yield call(uploadPaymentDataEffect, payload);
		yield put(uploadPaymentDetailsSuccess(data));
	} catch (e: any) {
		yield put(uploadPaymentDetailsError(e?.ErrorMsg));
	}
}
function* policyRenewalWAtcher({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);

		const datas = {
			policyDetail: {
				...payload.policyDetail,
				eiaNo: user.eiaNo,
			},
		};

		const { data } = yield call(policyRenewal, datas);

		// const data ={
		// 	"txnId": "9e1cddbe-29ae-71e8-a8d7-0289147bf541",
		// 	"timestamp": "2023-08-16T10:33:25.387994200",

		// 	"premiumAmount": 2095,
		// 	"coverage": "",
		// 	"responseTime": "26-Sep-2023 11:05:26:526",
		// 	"mobile": "4534455645",
		// 	"gst": "320.0",
		// 	"policyStatus": "Accept",
		// 	"productName": "Arogya Sanjeevani",
		// 	"customerName": "Anudeep  Deepa",
		// 	"transactionId": "FY260920231105265000000000378",
		// 	"annualGrossPremium": "1775",
		// 	"sumInsured": "100000",
		// 	"renewalQuoteNumber": "0000000004971334",
		// 	"renewalPremiumAmount": "2095",
		// 	"renewalDueDate": "2023-10-05 23:59:00.0",
		// 	"startTime": "2022-10-06 00:00:00.0",
		// 	"rzpOrderId": "order_MgqmGfVPJ2Hczb",
		// 	"previousPolicyNo": "0000000000358075",
		// 	"email": "",
		// 	"status": "Success"

		// }
		yield put(policyRenewalSuccess(data));
	} catch (e: any) {
		yield put(policyRenewalError(e?.ErrorMsg));
	}
}
function* policyUploadRenewalWAtcher({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);

		const datas = {
			policyDetail: {
				...payload.policyDetail,
				eiaNo: user.eiaNo,
			},
		};

		const { data } = yield call(policyRenewal, datas);

		// const data ={
		// 	"txnId": "9e1cddbe-29ae-71e8-a8d7-0289147bf543",
		// 	"timestamp": "2023-08-16T10:33:25.387994200",

		// 	"responseTime": "2022-02-23T17:44:00+05:30",
		// 	"errorCode": "200",
		// 	"transactionId": "NIC2023-09-21 18:35:22",
		// 	"status": "Success"

		// }
		yield put(uploadRenewalPaymentDetailsSuccess(data));
	} catch (e: any) {
		yield put(uploadRenewalPaymentDetailsError(e?.ErrorMsg));
	}
}
function* watchPayments() {
	yield takeEvery(fetchPaymentDetailsRequest.type, paymentDetailsWatcher);
	yield takeEvery(uploadPaymentDetailsRequest.type, uploadPaymentDataWatcher);
	yield takeEvery(policyRenewalRequest.type, policyRenewalWAtcher);
	yield takeEvery(
		uploadRenewalPaymentDetailsRequest.type,
		policyUploadRenewalWAtcher
	);
}

// eslint-disable-next-line import/prefer-default-export
export { watchPayments };
