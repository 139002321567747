import * as React from 'react';

import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import i18next from '../../../i18n';
import CustomButton from '../../../common/ui/CustomButton';
import theme from '../../../common/theme';

import CommonModal from '../../../common/ui/Modal/CommonModal';

import CustomRadioGroup from '../../../common/ui/Forms/FormInput/RadioButton/RadioGroup';
import { APP_ROUTES, AUTH_ROUTES } from '../../../common/constants/Routes';
import './Login.scss';

interface UserProps {
	open?: any;
	setOpen?: any;
	contentType?: any;
}

const LanguageSelector: React.FC<UserProps> = ({
	open,
	setOpen,
	contentType = 'LOGIN',
}) => {
	const { t } = useTranslation();

	const languageList = process.env.REACT_APP_MODE !== 'PROD' ? [
		{ value: 'en', label: 'English' },
		{ value: 'hi', label: 'हिंदी' },
		{ value: 'ta', label: 'தமிழ்' },
		{ value: 'tel', label: 'తెలుగు' },
		{ value: 'ml', label: 'മലയാളം' },
		{ value: 'kn', label: 'తెలుహు' },
		{ value: 'mr', label: 'मराठी' },
		{ value: 'pa', label: 'ਪੰਜਾਬੀ' },
	] : [
		{ value: 'en', label: 'English' },
		{ value: 'hi', label: 'हिंदी' },
		// { value: 'ta', label: 'தமிழ்' },
		// { value: 'tel', label: 'తెలుగు' },
		// { value: 'ml', label: 'മലയാളം' },
		// { value: 'kn', label: 'తెలుహు' },
		// { value: 'mr', label: 'मराठी' },
		// { value: 'pa', label: 'ਪੰਜਾਬੀ' },
	];
	const [selectedOption, setSelectedOption] = React.useState<any>('en');
	const handleOptionChange = (value: string) => {
		setSelectedOption(value);
	};
	const changeLanguage = () => {
		i18next.changeLanguage(selectedOption);
		i18next.language;
		localStorage.setItem('ln', selectedOption);
		setOpen(false);
	};

	React.useEffect(() => {
		localStorage.getItem('ln') === null ? localStorage.setItem('ln', 'en') : '';
		let currentLanguage = localStorage.getItem('ln');
		setSelectedOption(currentLanguage);
		// if (contentType === 'LOGIN') {
		// 	setSelectedOption('hi');
		// }
	}, [open]);

	return (
		<CommonModal
			onClose={() => setOpen(false)}
			open={open}
			modalClassName="px-20 py-20 "
			boxProps={{ width: 350, height: 550 }}
		>
			<Grid container>
				<Grid item xs={12} className=" ml-20">
					<Typography
						color={theme.palette?.common?.black}
						className="mb-10"
						fontSize={18}
						fontWeight={600}
					>
						{t('SELECT_LANGUAGE')}
						{/* Select Language */}
					</Typography>
				</Grid>
				<Grid item xs={12} className="ml-20">
					<CustomRadioGroup
						name="RadioGroup"
						options={languageList}
						value={selectedOption}
						onChange={handleOptionChange}
						size="small"
						inputClassName="my-4"
					/>{' '}
				</Grid>
				<Grid container justifyContent="space-between" className="mt-30 ml-20">
					<Grid className="py-5">
						<Typography
							color={theme.palette?.common?.black}
							className="mb-10 cursur-pointer"
							fontSize={18}
							onClick={() => setOpen(false)}
						>
							{contentType === 'LOGIN' ? 'BACK' : 'CANCEL'}
						</Typography>
					</Grid>
					<Link
						to={
							contentType === 'SIGNUP'
								? AUTH_ROUTES.SIGNUP
								: contentType === 'LOGIN'
									? AUTH_ROUTES.LOGIN
									: APP_ROUTES.DASHBOARD
						}
					>
						<Grid className="py-5 mr-30">
							<CustomButton
								text={contentType === 'SIGNUP' ? t('CONTINUE') : 'CHANGE'}
								variant="text"
								color="primary"
								fontSize={18}
								fontWeight={600}
								onClick={changeLanguage}
							// onClick={() => setOpen(false)}
							/>
						</Grid>
					</Link>
				</Grid>
			</Grid>
		</CommonModal>
	);
};
export default LanguageSelector;
