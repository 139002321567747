import { call, put, select, takeEvery } from 'typed-redux-saga';
import { fetchExistingPolicies, fetchInsSamadhanUploadDoc } from './addPolicy.service';
import { InsSamadhanUploadDocError, addManualPolicies, errorExistingPolicies, loadExistingPolicies, removePolicy, requestExistingPolicies, requestInsSamadhanUploadDoc, saveManualPolicies, selectExistingPoliciesFromEIA, selectInsurer, selectNewManualPolicies } from './addPolicy.slice';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import { setAlertInfo } from '../Common/Common.slice';
import { groupPoliciesByALCode } from '../../../common/utils';
import { selectInsurers } from '../PolicyScreen/Policies.slice';
import { getAnalytics, logEvent } from '@firebase/analytics';

const dest = localStorage.getItem('language');

function* handleFetchInsurers() {
	const user: any = yield* select(selectIsUserLoggedIn);
	const requestPayload = {
		Customer: {
			eiaNo: user.eiaNo,
		},
	};
	try {
		const { data }: any = yield* call(fetchExistingPolicies, requestPayload);
		// const data = {
		//     "txnId": "5b3b024a-50f4-4341-9930-46b67f6a543d",
		//     "timestamp": "2024-03-20T11:33:27.051143270",
		//     "Policies": [

		//         {
		//             "policyNo": "P/700002/01/2023/077165",
		//             "companyName": "Star Health and Allied Insurance Company Limited",
		//             "companyCode": "118",
		//             "planName": "Star Women care Insurance Policy",
		//             "customerName": "Reni Mathew",
		//             "alternateCode": "H",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/star_health_ins.png",
		//             "productType": "Both Hospitalization and Domiciliary expenses",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "2302205420670000003",
		//             "companyName": "HDFC ERGO General Insurance Co. Ltd.",
		//             "companyCode": "108",
		//             "planName": "PRIVATE CAR PACKAGE POLICY BUNDLED",
		//             "customerName": "RAHUL JOSHI",
		//             "alternateCode": "G",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/hdfc_ergo_general_ins.png",
		//             "productType": "",
		//             "vehicleNo": "MH12UF8419",
		//             "vehicleModel": "A6"
		//         },
		//         {
		//             "policyNo": "Harini123",
		//             "companyName": "SBI General Insurance Co. Ltd.",
		//             "companyCode": "117",
		//             "planName": "Private Motor 3 and 4 wheeler",
		//             "customerName": "Mrs Raksha Keshavrao Mundhe",
		//             "alternateCode": "G",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/sbi_general_ins.png",
		//             "productType": "",
		//             "vehicleNo": "MH29AR0887",
		//             "vehicleModel": "EON"
		//         },
		//         {
		//             "policyNo": "Harini4343424",
		//             "companyName": "SBI General Insurance Co. Ltd.",
		//             "companyCode": "117",
		//             "planName": "Private Motor 3 and 4 wheeler",
		//             "customerName": "Mrs Raksha Keshavrao Mundhe",
		//             "alternateCode": "G",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/sbi_general_ins.png",
		//             "productType": "",
		//             "vehicleNo": "MH29AR0887",
		//             "vehicleModel": "EON"
		//         },
		//         {
		//             "policyNo": "HariniDone",
		//             "companyName": "SBI General Insurance Co. Ltd.",
		//             "companyCode": "117",
		//             "planName": "Private Motor 3 and 4 wheeler",
		//             "customerName": "Mrs Raksha Keshavrao Mundhe",
		//             "alternateCode": "G",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/sbi_general_ins.png",
		//             "productType": "",
		//             "vehicleNo": "MH29AR0887",
		//             "vehicleModel": "EON"
		//         },
		//         {
		//             "policyNo": "HariniTest555",
		//             "companyName": "SBI General Insurance Co. Ltd.",
		//             "companyCode": "117",
		//             "planName": "Private Motor 3 and 4 wheeler",
		//             "customerName": "Mrs Raksha Keshavrao Mundhe",
		//             "alternateCode": "G",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/sbi_general_ins.png",
		//             "productType": "",
		//             "vehicleNo": "MH29AR0887",
		//             "vehicleModel": "EON"
		//         },
		//         {
		//             "policyNo": "HariniTest666",
		//             "companyName": "SBI General Insurance Co. Ltd.",
		//             "companyCode": "117",
		//             "planName": "Private Motor 3 and 4 wheeler",
		//             "customerName": "Mrs Raksha Keshavrao Mundhe",
		//             "alternateCode": "G",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/sbi_general_ins.png",
		//             "productType": "",
		//             "vehicleNo": "MH29AR0887",
		//             "vehicleModel": "EON"
		//         },
		//         {
		//             "policyNo": "Harinipolicy",
		//             "companyName": "SBI General Insurance Co. Ltd.",
		//             "companyCode": "117",
		//             "planName": "Private Motor 3 and 4 wheeler",
		//             "customerName": "Mrs Raksha Keshavrao Mundhe",
		//             "alternateCode": "G",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/sbi_general_ins.png",
		//             "productType": "",
		//             "vehicleNo": "MH29AR0887",
		//             "vehicleModel": "EON"
		//         },
		//         {
		//             "policyNo": "QWERTY11",
		//             "companyName": "Bandhan Life Insurance Limited",
		//             "companyCode": "01",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "L",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/aegon_life_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "QQW",
		//             "companyName": "Bharti AXA Life Insurance",
		//             "companyCode": "04",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "L",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/bharati_axa_life_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "0000000026176963",
		//             "companyName": "Bharti AXA Life Insurance",
		//             "companyCode": "04",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "L",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/bharati_axa_life_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "0000000026176963",
		//             "companyName": "Bharti AXA General Insurance Co. Ltd.",
		//             "companyCode": "104",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "H",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/bharati_axa_general_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "0000000026176963",
		//             "companyName": "Bajaj Allianz General Insurance Co. Ltd.",
		//             "companyCode": "103",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "G",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/bajaj_allianz_general_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "0000000026176963",
		//             "companyName": "Bharti AXA General Insurance Co. Ltd.",
		//             "companyCode": "104",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "G",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/bharati_axa_general_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "0000000026176963",
		//             "companyName": "AVIVA Life Insurance",
		//             "companyCode": "02",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "L",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/aviva_life_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "0000000026176963",
		//             "companyName": "Bajaj Allianz Life Insurance",
		//             "companyCode": "03",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "L",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/bajaj_allianz_life_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "SUNDAR",
		//             "companyName": "Niva Bupa Health Insurance Company Limited",
		//             "companyCode": "112",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "H",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/max_bupa_health_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "DUMMY01",
		//             "companyName": "Star Health and Allied Insurance Company Limited",
		//             "companyCode": "118",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "G",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/star_health_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "SAMPLE",
		//             "companyName": "Star Health and Allied Insurance Company Limited",
		//             "companyCode": "118",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "H",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/star_health_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "121212121212",
		//             "companyName": "Aditya Birla Sun Life Insurance Company Ltd",
		//             "companyCode": "05",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "L",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/birla_sun_life_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "121212121212",
		//             "companyName": "Bharti AXA Life Insurance",
		//             "companyCode": "04",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "L",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/bharati_axa_life_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "0000000026176963",
		//             "companyName": "AVIVA Life Insurance",
		//             "companyCode": "02",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "L",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/aviva_life_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "7682738273",
		//             "companyName": "Canara HSBC Life Insurance",
		//             "companyCode": "06",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "L",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/canara_hsbc_life_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "GRTFF6456665",
		//             "companyName": "Pramerica Life Insurance Limited",
		//             "companyCode": "07",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "L",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/pramerica_life_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "4654HGICGJ",
		//             "companyName": "Bajaj Allianz Life Insurance",
		//             "companyCode": "03",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "L",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/bajaj_allianz_life_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "0000000000369035",
		//             "companyName": "SBI General Insurance Co. Ltd.",
		//             "companyCode": "117",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "H",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/sbi_general_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },
		//         {
		//             "policyNo": "NULL",
		//             "companyName": "Bajaj Allianz Life Insurance",
		//             "companyCode": "03",
		//             "planName": "",
		//             "customerName": "",
		//             "alternateCode": "L",
		//             "companyLogo": "https://policygenie2.blob.core.windows.net/insurerlogo/bajaj_allianz_life_ins.png",
		//             "productType": "",
		//             "vehicleNo": "",
		//             "vehicleModel": ""
		//         },

		//     ],
		//     "sessionId": "",
		//     "Source": {
		//         "appType": "REACT",
		//         "osVersion": "11",
		//         "deviceId": "",
		//         "deviceName": "DESKTOP",
		//         "deviceOS": "WINDOWS",
		//         "appVersion": "2.0",
		//         "clientIp": "0.0.0.0",
		//         "origin": "RI"
		//     },
		//     "Customer": {
		//         "eiaNo": "9200000080508"
		//     },
		//     "errorCode": "0",
		//     "errorDescription": ""
		// }
		console.log(data);
		yield* put(loadExistingPolicies(data?.Policies || []));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'FetchInsurers_button_click', {
				button_name: 'FetchInsurers_button',
				Select_Languages: dest,
				Status: 'Success',
				Devices_type: 'Web',
			});
		}
	} catch (e: any) {
		yield* put(errorExistingPolicies(e.message));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'FetchInsurers_button_clic', {
				button_name: 'FetchInsurers_button',
				Select_Languages: dest,
				Status: 'failure',
				Devices_type: 'Web',
			});
		}
	}
}

function* handleFetchInsSamadhanUploadDoc({ payload }: any) {
	const user: any = yield* select(selectIsUserLoggedIn);
	const insurerList: any = yield* select(selectInsurers);
	try {
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				...payload,
				//documentBase64: payload
			},
		};
		const { data } = yield* call(fetchInsSamadhanUploadDoc, requestPayload);

		const response = {
			policyNo: data?.data?.policy_number,
			insurerCode: data?.data?.company_id,
			companyLogo: insurerList.filter((item: any) => item.Code === data?.data?.company_id)?.[0]?.insurerLogo,
			alternateCode: data?.data?.document_type,
			companyName: data?.data?.company_name,
			customerName: data?.data?.policyholder_name,
			requestFrom: payload?.documentType,
			ackId: data?.data?.ack_id
		}

		yield* put(addManualPolicies(response));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Upload Doc_button_click', {
				button_name: 'Upload Doc_button',
				Select_Languages: dest,
				Status: 'Success',
				Devices_type: 'Web',
			});
		}
	} catch (e: any) {
		yield* put(
			setAlertInfo({
				open: true,
				type: 'FAILED',
				description: e.response.data.errorDescription,
			})
		);
		yield* put(InsSamadhanUploadDocError(e.message));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Upload Doc_button_click', {
				button_name: 'Upload Doc_button',
				Select_Languages: dest,
				Status: 'Failure',
				Devices_type: 'Web',
			});
		}
	}
}

function* handleAddNewManualPolicy({ payload }: any) {
	try {
		const newManualPolicies = yield* select(selectNewManualPolicies);
		const existingPoliciesFromEIA = yield* select(
			selectExistingPoliciesFromEIA
		);

		const groupedPolicies = groupPoliciesByALCode(existingPoliciesFromEIA);
		const existingPolicies = [
			{ code: 'L', list: groupedPolicies.L || [] },
			{ code: 'G', list: groupedPolicies.G || [] },
			{ code: 'H', list: groupedPolicies.H || [] },
		];

		const currentNewPolicy = payload;
		let lifePolicies =
			newManualPolicies.find((val: any) => val.code === 'L')?.list || []; //.concat(state.existingPoliciesFromEIA.data.find((val: any) => val.alternateCode === 'L'))
		let healthPolicies =
			newManualPolicies.find((val: any) => val.code === 'H')?.list || []; //.concat(state.existingPoliciesFromEIA.data.find((val: any) => val.alternateCode === 'H'))
		let motorPolicies =
			newManualPolicies.find((val: any) => val.code === 'G')?.list || []; //.concat(state.existingPoliciesFromEIA.data.find((val: any) => val.alternateCode === 'G'))

		const isPolicyNoExists = (list: any[], policyNo: string, amcCode: string) => list.some((item) => item.policyNo.toLowerCase() === policyNo.toLowerCase() && (item.companyCode || item.insurerCode) === amcCode);

		let exisitingLifePolicies =
			existingPolicies.find((val: any) => val.code === 'L')?.list || []; //.concat(state.existingPoliciesFromEIA.data.find((val: any) => val.alternateCode === 'L'))
		let existingHealthPolicies =
			existingPolicies.find((val: any) => val.code === 'H')?.list || []; //.concat(state.existingPoliciesFromEIA.data.find((val: any) => val.alternateCode === 'H'))
		let existingMotorPolicies =
			existingPolicies.find((val: any) => val.code === 'G')?.list || []; //.concat(state.existingPoliciesFromEIA.data.find((val: any) => val.alternateCode === 'G'))

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Add_New_Manual_Policy_button_click', {
				button_name: 'Add_New_Manual_Policy_button',
				Select_Languages: dest,
				Status: 'Success',
				Devices_type: 'Web',
			});
		}

		// if (
		//     (currentNewPolicy.alternateCode === 'L' &&
		//         isPolicyNoExists(exisitingLifePolicies, currentNewPolicy.policyNo)) || (currentNewPolicy.alternateCode === 'H' &&
		//             isPolicyNoExists(existingHealthPolicies, currentNewPolicy.policyNo)) || (currentNewPolicy.alternateCode === 'G' &&
		//                 isPolicyNoExists(existingMotorPolicies, currentNewPolicy.policyNo))
		// ) {
		//     const existingPolicyByNo = existingPoliciesFromEIA.filter((item: any) => item.policyNo.toLowerCase() === currentNewPolicy.policyNo.toLowerCase())[0]

		//     console.log(existingPolicyByNo, existingPoliciesFromEIA)

		//     const alertInfo: any = {
		//         open: true,
		//         type: 'FAILED',
		//         description: existingPolicyByNo?.customerName === '' ? 'Your Policy Conversion request is being processed. Please wait and check again later.' : 'Your policy has already been converted. Please try adding another policy.',
		//     };
		//     yield* put(setAlertInfo(alertInfo));

		// }

		if (isPolicyNoExists(existingPoliciesFromEIA, currentNewPolicy.policyNo, currentNewPolicy.insurerCode)) {
			const existingPolicyByNo = existingPoliciesFromEIA.filter((item: any) => item.policyNo.toLowerCase() === currentNewPolicy.policyNo.toLowerCase())[0];

			const alertInfo: any = {
				open: true,
				type: 'FAILED',
				description: existingPolicyByNo?.customerName === '' ? 'Your Policy Conversion request is being processed. Please wait and check again later.' : 'Your policy has already been converted. Please try adding another policy.',
			};
			yield* put(setAlertInfo(alertInfo));
		}
		else if (!isPolicyNoExists(lifePolicies, currentNewPolicy.policyNo, currentNewPolicy.insurerCode) && !isPolicyNoExists(healthPolicies, currentNewPolicy.policyNo, currentNewPolicy.insurerCode) && !isPolicyNoExists(motorPolicies, currentNewPolicy.policyNo, currentNewPolicy.insurerCode)) {

			if (
				currentNewPolicy.alternateCode === 'L' &&
				!isPolicyNoExists(lifePolicies, currentNewPolicy.policyNo, currentNewPolicy.insurerCode)
			) {

				lifePolicies = [...lifePolicies, currentNewPolicy]
			}
			else if (
				currentNewPolicy.alternateCode === 'H' &&
				!isPolicyNoExists(healthPolicies, currentNewPolicy.policyNo, currentNewPolicy.insurerCode)
			) {
				healthPolicies = [...healthPolicies, currentNewPolicy]
			}
			else if (
				currentNewPolicy.alternateCode === 'G' &&
				!isPolicyNoExists(motorPolicies, currentNewPolicy.policyNo, currentNewPolicy.insurerCode)
			) {
				motorPolicies = [...motorPolicies, currentNewPolicy]
			}
		}
		else {

			yield* put(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: 'This policy number already added to the list.',
				})
			);

		}

		yield* put(
			saveManualPolicies([
				{ code: 'L', list: lifePolicies },
				{ code: 'H', list: healthPolicies },
				{ code: 'G', list: motorPolicies },
			])
		);
	} catch (e: any) {
		console.log(e);

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Add_New_Manual_Policy_button_click', {
				button_name: 'Add_New_Manual_Policy_button',
				Select_Languages: dest,
				Status: 'Failure',
				Devices_type: 'Web',
			});
		}

	}
}

function* handleRemovePolicy({ payload }: any) {

	const newManualPolicies = yield* select(selectNewManualPolicies);

	let healthPolicies = (newManualPolicies.find((val: any) => val.code === 'H')?.list || []);
	let lifePolicies = (newManualPolicies.find((val: any) => val.code === 'L')?.list || []);
	let motorPolicies = (newManualPolicies.find((val: any) => val.code === 'G')?.list || []);

	healthPolicies = healthPolicies.filter((item: any) =>  item.policyNo !== payload.policyNo );
	lifePolicies = lifePolicies.filter((item: any) => item.policyNo !== payload.policyNo);
	motorPolicies = motorPolicies.filter((item: any) => item.policyNo !== payload.policyNo);



	yield* put(saveManualPolicies([{ code: "L", list: lifePolicies }, { code: "H", list: healthPolicies }, { code: "G", list: motorPolicies }]));
}


function* watchAddPolicies() {
	yield* takeEvery(requestExistingPolicies.type, handleFetchInsurers);
	yield* takeEvery(requestInsSamadhanUploadDoc.type, handleFetchInsSamadhanUploadDoc);
	yield* takeEvery(addManualPolicies.type, handleAddNewManualPolicy);
	yield* takeEvery(removePolicy.type, handleRemovePolicy);

}

export { watchAddPolicies };
