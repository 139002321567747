import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import '../../../Policy.scss';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { ReactComponent as IIcon } from '../../../../../../common/icons/i-icon.svg';
import {
	fetchViewPolicyDetails,
	viewPolicyList,
	viewPolicyLoading,
} from '../../../Policies.slice';
import CoveredMembers from './CoveredMembers';
import Payor from './Payor';
import TotalPremium from './TotalPremium';
import Payment from './Payment';
import Communication from './Communication';
import Riders from './Riders';
import Exclusion from './Exclusion';
import Nominees from './Nominees';
import Annuity from './Annuity';
import Benefit from './Benefit';
import Fund from './Fund';
import DownloadStatements from './DownloadStatements';

import Separator from '../../../../../../common/ui/Separator';

import theme from '../../../../../../common/theme';

import PolicyDetailsHeader from '../PolicyDetailsHeader';
import PolicyDetailsData from '../common/PolicyDetailsData';
import FreeLookPeriod from './FreeLookPeriod';
import RenewalAmount from '../RenewalAmount';
import Spinner from '../../../../../../common/ui/Spinner';
import DownloadDocument from './DownloadDocument';
// import CustomButton from '../../../../../../common/ui/CustomButton';
// import SvgConverter from '../../../../../../common/SvgConverter';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';


const LifeDetails = () => {
	const location = useLocation();
	const policyDetails = location.state.items;
	const isArchive = location.state.footerType === "archive";
	const policyNum = policyDetails?.policyNo;
	const altCode = policyDetails?.alternateCode;
	const insCode = policyDetails?.companyCode
		? policyDetails?.companyCode
		: policyDetails?.insurerCode;
	const VerifiedFlag = policyDetails?.verifiedFlag;
	const dispatch = useDispatch();
	const ViewPolicyData = useSelector(viewPolicyList);
	const lifeDetails =
		ViewPolicyData.policyDetails || ViewPolicyData.policyDetailsIns;
	const lifePolicyDataLoading = useSelector(viewPolicyLoading);

	const lifeData = location.state;

	useEffect(() => {
		dispatch(
			fetchViewPolicyDetails({
				policyNum,
				altCode,
				insCode,
				VerifiedFlag,
			} as any)
		);
	}, []);

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Life Policy Screen',
		});
	}, []);
	return (
		<Grid container justifyContent="center" alignItems="center">
			<Grid
				container
				item
				className="policy-details py-10 mb-20"
				lg={12}
				md={12}
				xl={12}
				sm={12}
				columnSpacing={4}
				mb={5}
			>
				<Grid item lg={12} md={12} xl={12} sm={12} container>
					<PolicyDetailsHeader />
				</Grid>

				{lifePolicyDataLoading ? (
					<Grid
						item
						xs={12}
						justifyContent="center"
						display="flex"
						alignItems="center"
						marginTop={10}
					>
						<Spinner />
					</Grid>
				) : (
					<>
						<Grid item lg={4} xs={12} md={5} xl={4} sm={6} className="mt-5">
							<PolicyDetailsData policyDetails={lifeDetails} />
							{process.env.REACT_APP_MODE !== 'PROD' && (
								<>
									{lifeDetails?.freelookPeriod > 0 ? (
										<>
											<Grid container item xs={12}>
												<FreeLookPeriod freeLookPeriodDetails={lifeDetails} />
											</Grid>
											<Grid item xs={12} className="py-10">
												<Separator color={theme.palette.grey[400]} />
											</Grid>
										</>
									) : (
										''
									)}
								</>
							)}
							<RenewalAmount renewalDetails={lifeDetails} policyType="life" />
							<Grid container item xs={12} className="py-10 separator-mobile">
								<Separator color={theme.palette.grey[400]} />
							</Grid>
						</Grid>
						<Grid item lg={4} xs={12} md={4} xl={4} sm={6}>
							<CoveredMembers
								CoveredMembersData={lifeDetails?.CoveredMembers}
								lifeDetails={lifeDetails}
							/>
						</Grid>
						<Grid container item xs={12} className="py-10 separator-small">
							<Separator color={theme.palette.grey[400]} />
						</Grid>
						<Grid item lg={4} xs={12} md={3} xl={4} sm={6} mt={3}>
							<Payor lifeDetails={lifeDetails} />
							<Grid container item xs={12} className="py-10 separator-mobile">
								<Separator color={theme.palette.grey[400]} />
							</Grid>
							<TotalPremium lifeDetails={lifeDetails} />
							<Grid container item xs={12} className="py-10 separator-mobile">
								<Separator color={theme.palette.grey[400]} />
							</Grid>
						</Grid>
						<Grid container item xs={12} className="py-10 separator-large ">
							<Separator color={theme.palette.grey[400]} />
						</Grid>
						<Grid
							container
							item
							lg={4}
							xs={12}
							md={4}
							xl={4}
							sm={6}
							className="py-10 mt-15"
						>
							<Grid item xs={12}>
								<Payment lifeDetails={lifeDetails} />
								<Grid container item xs={12} className="py-10 separator-mobile">
									<Separator color={theme.palette.grey[400]} />
								</Grid>
								<Communication lifeDetails={lifeDetails} isArchive={isArchive} />
							</Grid>
						</Grid>
						<Grid container item xs={12} className="py-10 separator-small">
							<Separator color={theme.palette.grey[400]} />
						</Grid>
						<Grid
							container
							item
							lg={4}
							xs={12}
							md={4}
							xl={4}
							sm={6}
							className="py-10"
						>
							<Riders riderDetails={lifeDetails?.Riders} />{' '}
						</Grid>
						<Grid
							container
							item
							lg={4}
							xs={12}
							md={4}
							xl={4}
							sm={6}
							className="py-10"
						>
							<Grid item xs={12}>
								<Grid container item xs={12} className="py-10 separator-mobile">
									<Separator color={theme.palette.grey[400]} />
								</Grid>
								<Exclusion ExclusionDetails={lifeDetails?.Exclusions} />{' '}
								<Grid container item xs={12} className="py-10 ">
									<Separator color={theme.palette.grey[400]} />
								</Grid>
								<Nominees nomineeDetails={lifeDetails?.Nominees} />
							</Grid>
						</Grid>{' '}
						<Grid container item xs={12} className="py-10 separator-large ">
							<Separator color={theme.palette.grey[400]} />
						</Grid>
						<Grid container item xs={12} className="py-10 separator-small">
							<Separator color={theme.palette.grey[400]} />
						</Grid>
						<Grid container item lg={4} xs={12} md={4} xl={4} sm={6}>
							<Annuity annuityDetails={lifeDetails?.Annuity_details} />
							<Grid container item xs={12} className="py-10 ">
								<Separator color={theme.palette.grey[400]} />
							</Grid>
							<Benefit benefitDetails={lifeDetails?.Benefit} />{' '}
							<Grid container item xs={12} className="py-10 separator-mobile">
								<Separator color={theme.palette.grey[400]} />
							</Grid>
						</Grid>
						<Grid container item lg={4} xs={12} md={4} xl={4} sm={6}>
							<Fund fundDetails={lifeDetails} />
							<DownloadDocument
								downloadDocumentDetails={lifeDetails}
								downloadData={lifeData}
							/>
						</Grid>
						<Grid container item xs={12} className="py-10 separator-small">
							<Separator color={theme.palette.grey[400]} />
						</Grid>
						<Grid container item lg={4} xs={12} md={4} xl={4} sm={6}>
							{' '}
							<Grid container item lg={4} xs={12} md={4} xl={12} sm={4}>
								<DownloadStatements
									downloadStatementsDetails={lifeDetails?.Statements}
									downloadData={lifeData}
								/>
							</Grid>
							<Grid container item xs={12} className="py-10">
								<Separator color={theme.palette.grey[400]} />
							</Grid>
						</Grid>
						{/* <Grid
							container
							item
							lg={4}
							xs={12}
							md={4}
							xl={4}
							sm={6}
							display="flex"
							justifyContent="space-between"
						>
							<CustomButton
								text="SURRENDAR POLICY"
								variant="text"
								color="primary"
								showIcon={false}
								fontSize={14}
							/>
							<SvgConverter Icon={IIcon} className="mr-15" />
						</Grid>
						<Grid container item xs={12} className="py-10 ">
							<Separator color={theme.palette.grey[400]} />
						</Grid> */}
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default LifeDetails;
