import React from 'react';
import { Grid } from '@mui/material';
import '../Nominee.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import { ReactComponent as OrangeRupee } from '../../../../common/icons/orange_rupee.svg';
import SvgConverter from '../../../../common/SvgConverter';

const NomineeCardHeader = ({
	productName,
	selfName,
	sumAssured,
	premium,
	bankImage,
}: {
	productName: string;
	selfName: string;
	sumAssured: string | number;
	premium: string | number;
	bankImage: any;
}) => {
	const { t } = useTranslation();

	return (
		<Grid item lg={12} xl={9} md={12} xs={12} sm={12} container className="">
			<Grid
				container
				item
				xs={12}
				justifyContent="space-between"
				className="py-20"
			>
				<Grid item xs={9}>
					<Typography
						fontSize={16}
						fontWeight={600}
						color={theme.palette.primary.main}
						className="mb-5"
					>
						{productName}
					</Typography>
					<Typography fontSize={14} color={theme.palette.primary.main}>
						{t('LIFE_ASSURED')}
					</Typography>
					<Typography
						fontSize={16}
						fontWeight={600}
						color={theme.palette.common.black}
					>
						{selfName}
					</Typography>
				</Grid>
				<Grid item xs={3} className="mt-10">
					<img
						src={bankImage}
						width={60}
						height={60}
						style={{ objectFit: 'contain', border: '1px solid #d7d7d7' }}
						alt="bank"
					/>
				</Grid>
			</Grid>

			<Grid container item xs={12} className="mt-n25 py-20">
				<Grid item>
					<Typography fontSize={14} color={theme.palette.primary.main}>
						{t('SUM_ASSURED')}
					</Typography>{' '}
					<Grid container spacing={0.5} alignItems="center">
						<Grid item className="mt-1">
							<SvgConverter Icon={OrangeRupee} />
						</Grid>
						<Grid item>
							<Typography
								className="f-16 fw-600"
								color={theme.palette.common.black}
							>
								{sumAssured}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item className="px-10 ">
					<Separator
						orientation="vertical"
						color={theme.palette.primary.main}
					/>
				</Grid>
				<Grid item className="">
					<Typography fontSize={14} color={theme.palette.primary.main}>
						{t('PREMIUM')}
					</Typography>
					<Grid container spacing={0.5} alignItems="center">
						<Grid item className="mt-1">
							<SvgConverter Icon={OrangeRupee} />
						</Grid>
						<Grid item>
							<Typography
								className="f-16 fw-600"
								color={theme.palette.common.black}
							>
								{premium}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default NomineeCardHeader;
