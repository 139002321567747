import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import '../Nominee.scss';
import Typography from '@mui/material/Typography';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import { ReactComponent as Cancel } from '../../../../common/icons/Cancel-small.svg';
import CustomButton from '../../../../common/ui/CustomButton';
import NomineeCardHeader from './NomineeCardHeader';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { ReactComponent as Add } from '../../../../common/icons/plus-icon.svg';
import { ReactComponent as Correct } from '../../../../common/icons/tick-icon.svg';
import NomineeModal from './NomineeModal';
import NomineeListModal from './NomineeListModal';
import {
	selectUpdateNomineeIsLoading,
	updateNomineeRequest,
} from '../Nominee.slice';
import NomineeForm from './NomineeForm';
import { setAlertInfo } from '../../Common/Common.slice';
import { ageCalculator } from '../../../../common/utils';
import ApiModal from '../../../../common/ui/Modal/ApiModal';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';



const EditNominee = () => {
	const dispatch = useDispatch();
	const [addFormData, setAddFormData] = useState([]);
	const [submitModalVisible, setsubmitModalVisible] = useState(false);
	const [addModalVisible, setAddModalVisible] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();
	const cardData = location.state?.cardData || {
		planName: '',
		lifeAssuredName: '',
		sumAssured: '',
		basicPremiumAmount: '',
		companyLogo: '',
	};
	const handleAddModal = () => {
		setAddModalVisible(true);
	};
	const {
		register,
		handleSubmit,
		unregister,
		formState: { errors, isValid },
		setValue,
		getValues,
		watch,
		control,
		reset,
		resetField,
		getFieldState
	} = useForm({
		// defaultValues: {
		// 	nomineeData: [],
		// },
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'nomineeData',
	} as any);

	useEffect(() => {
		if (cardData.Nominees) {
			const nomineeData = cardData.Nominees.map((item: any) => {
				const [Date, Month, Year] = item.nomineeDOB.split('-');
				return {
					nomineeSeqID: item.nomineeSeqID || '0',
					nomineeSentToInsurer: item.nomineeSentToInsurer ?? '',
					nomineeFirstName: (item.nomineeFirstName || '').trim() || '',
					nomineeMiddleName: (item.nomineeMiddleName || '').trim() || '',
					nomineeLastName: (item.nomineeLastName || '').trim() || '',
					nomineePercentage: item.nomineePercentage ?? '0',
					nomineeAge: item.nomineeAge ?? '',
					date: Date ?? '',
					month: Month ?? '',
					year: Year ?? '',
					relationshipName: item.relationshipName ?? '',
					relationshipCode: item.relationshipCode ?? '',
					address1: item.address1 ?? '',
					address2: item.address2 ?? '',
					address3: item.address3 ?? '',
					city: item.city ?? '',
					state: item.state ?? '',
					country: item.country ?? '',
					pincode: item.pincode ?? '',
					nomineeImageBase64: item.nomineeImageBase64 ?? '',
					fullAddress: item.fullAddress ?? '',
					addressSameAsLifeAssured: item.addressSameAsLifeAssured ?? '',
					PermanentAddress: item.PermanentAddress ?? '',

					Appointee:
						item?.Appointee &&
						item?.Appointee?.map((values: any) => {
							const [dobDay, dobMonth, dobYear] = values?.dob.split('-');
							return {
								firstName: values?.firstName ?? '',
								middleName: values?.middleName || '',
								lastName: values?.lastName || '',
								dob: values?.dob ?? '',
								nomineeRelationship: values?.nomineeRelationship ?? '',
								addressSameAsNominee: values?.addressSameAsNominee ?? 'N',
								address1: values?.address1 ?? '',
								address2: values?.address2 ?? '',
								address3: values?.address3 ?? '',
								city: values?.city ?? '',
								state: values?.state ?? '',
								country: values?.country ?? '',
								pincode: values?.pincode ?? '',
								fullAddress: values?.fullAddress ?? '',
								appointeeImageBase64: values?.appointeeImageBase64 ?? '',
								date: dobDay ?? '',
								month: dobMonth ?? '',
								year: dobYear ?? '',
							};
						}),
				};
			});
			setValue('nomineeData', nomineeData);
		}
	}, [cardData?.Nominees]);

	const numberOfForms = Number(fields.length);
	const nomineeCount = Number(cardData?.nomineeCount);

	const [removeFormData, setRemoveFormData] = useState<
		{ id: string; type: string }[]
	>([]);

	const isNomineeUpdatingLoading = useSelector(selectUpdateNomineeIsLoading);

	const percentage: any = watch();
	// const percentage?.nomineesFormData = watch('nomineeData');
	const percentageCalc = useMemo(
		() =>
			percentage.nomineeData &&
			percentage.nomineeData
				.map((item: any) => parseInt(item.nomineePercentage, 10))
				.filter((percentage: number) => !isNaN(percentage)),
		[percentage]
	);
	const sumOfPercentages = useMemo(
		() =>
			percentageCalc &&
			percentageCalc.reduce(
				(sum: number, percentage: number) => sum + percentage,
				0
			),
		[percentageCalc]
	);

	const removeNominee = (index: number) => {
		const removedItem = {
			...fields[index],
			type: 'REMOVE',
		};
		remove(index);
		setRemoveFormData((prev) => [...prev, removedItem]);
	};

	const addNomineeModal = () => {
		setAddModalVisible(false);
		const nomineeData = {
			type: 'ADD',
			nomineeSeqID: '0',
			nomineeSentToInsurer: '',
			nomineeFirstName: '',
			nomineeMiddleName: '',
			nomineeLastName: '',
			nomineePercentage: '',
			nomineeAge: '',
			date: '',
			month: '',
			year: '',
			relationshipName: '',
			relationshipCode: '',
			address1: '',
			address2: '',
			address3: '',
			city: '',
			country: '',
			pincode: '',
			state: '',
			nomineeImageBase64: '',
			Appointee: [
				{
					firstName: '',
					middleName: '',
					lastName: '',
					dob: '',
					gender: '',
					nomineeRelationship: '',
					appointeeImageBase64: '',
					appointeeImageName: '',
					relationshipName: '',
					address1: '',
					address2: '',
					address3: '',
					city: '',
					state: '',
					country: '',
					pincode: '',
					fullAddress: '',
					addressSameAsNominee: '',
					appointeeImageRequired: 'Y',
				},
			],
		};
		append(nomineeData);
	};

	const addNomineeCloseModal = (data: any) => {
		setAddModalVisible(false);
		const [Date, Month, Year] = data.dob.split('-');
		const nomineeData = {
			type: 'ADD',
			nomineeSeqID: '0',
			nomineeSentToInsurer: data.addressVerified,
			nomineeFirstName: data.firstName,
			nomineeMiddleName: data.middleName,
			nomineeLastName: data.lastName,
			nomineePercentage: '',
			nomineeAge: data.nomineeAge,
			date: Date,
			month: Month,
			year: Year,
			relationshipName: data.relationName,
			relationshipCode: data.relationCode,
			address1: data.address1,
			address2: data.address2,
			address3: data.address3,
			city: data.city,
			country: data.country,
			pincode: data.pin,
			state: data.state,
		};
		const nomineeExists =
			percentage?.nomineesFormData &&
			percentage?.nomineesFormData.some((nominee: any) => {
				const { date, month, year } = nominee;
				return (
					nominee.nomineeFirstName === nomineeData.nomineeFirstName &&
					nominee.nomineeMiddleName === nomineeData.nomineeMiddleName &&
					nominee.nomineeLastName === nomineeData.nomineeLastName &&
					nominee.relationshipName === nomineeData.relationshipName &&
					nominee.relationshipCode === nomineeData.relationshipCode &&
					date === nomineeData.date &&
					month === nomineeData.month &&
					year === nomineeData.year
				);
			});
		if (!nomineeExists) {
			append(nomineeData);
		} else {
			const alertMessage = `${nomineeData.nomineeFirstName} already exists as a nominee. Please select another person.`;
			dispatch(
				setAlertInfo({
					type: 'FAILED',
					open: true,
					description: alertMessage,
				})
			);
		}
	};
	const hasBase64Tag = (data: any) => {
		let flag = true;
		if (
			data &&
			Array.isArray(data.nomineeData) &&
			data.nomineeData.length > 0
		) {
			for (const [i, nominee] of data.nomineeData.entries()) {
				register(`nomineeData[${i}].nomineeImageBase64`, {
					validate: () => "Please upload ID Document for the update data"
				})

				if (getFieldState(`nomineeData[${i}].nomineeImageBase64`).isDirty) {
					register(`nomineeData[${i}].nomineeImageBase64`, {
						validate: () => true
					})
					flag = false;
				}
				else if (nominee.nomineeFirstName !== (cardData.Nominees?.[i]?.nomineeFirstName || '').trim() || nominee.nomineeMiddleName !== (cardData.Nominees?.[i]?.nomineeMiddleName || '').trim() || nominee.nomineeLastName !== (cardData.Nominees?.[i]?.nomineeLastName || '').trim()) {
					flag = false;
				}
				else if (nominee.nomineePercentage != cardData.Nominees?.[i]?.nomineePercentage) {

					flag = false;
				}
				else if (nominee.addressSameAsLifeAssured != cardData.Nominees?.[i]?.addressSameAsLifeAssured) {

					flag = false;
				}
				else if (nominee.address1 != cardData.Nominees?.[i]?.address1) {

					flag = false;
				}
				else if (nominee.address2 != cardData.Nominees?.[i]?.address2) {

					flag = false;
				}
				else if (nominee.address3 != cardData.Nominees?.[i]?.address3) {

					flag = false;
				}
				else if (nominee.city != cardData.Nominees?.[i]?.city) {
					flag = false;
				}

				else if (nominee.pincode != cardData.Nominees?.[i]?.pincode) {
					flag = false;
				}
				else if ([nominee.date, nominee.month, nominee.year].join('-') != cardData.Nominees?.[i]?.nomineeDOB) {
					flag = false;
				}
				else {
					register(`nomineeData[${i}].nomineeImageBase64`, {
						validate: () => true
					})
				}

				if (
					nominee.Appointee &&
					Array.isArray(nominee.Appointee) &&
					nominee.Appointee.length > 0 && cardData.Nominees?.[i]?.Appointee.length > 0
				) {
					for (const appointee of nominee.Appointee) {

						const appointeeCurrent = cardData.Nominees?.[i]?.Appointee[0];

						register(`nomineeData[${i}].Appointee.[0].appointeeImageBase64`, {
							validate: () => "Please upload ID Document for the update data"
						})

						if (getFieldState(`nomineeData[${i}].Appointee.[0].appointeeImageBase64`).isDirty) {
							register(`nomineeData[${i}].Appointee.[0].appointeeImageBase64`, {
								validate: () => true
							})
							flag = false;
						}
						else if (appointee.firstName != appointeeCurrent?.firstName || appointee.middleName != appointeeCurrent?.middleName || appointee.lastName != appointeeCurrent?.lastName) {
							flag = false;
						}
						else if (appointee.address1 != appointeeCurrent?.address1) {

							flag = false;
						}
						else if (appointee.addressSameAsNominee != appointeeCurrent?.addressSameAsNominee) {

							flag = false;
						}
						else if (appointee.address2 != appointeeCurrent?.address2) {

							flag = false;
						}
						else if (appointee.address3 != appointeeCurrent?.address3) {

							flag = false;
						}

						else if (appointee.pincode != appointeeCurrent?.pincode) {
							flag = false;
						}
						else if (appointee.city != appointeeCurrent?.city) {
							flag = false;
						}
						else if (appointeeCurrent.dob != appointee?.dob) {
							flag = false;
						}
						else {
							register(`nomineeData[${i}].Appointee.[0].appointeeImageBase64`, {
								validate: () => true
							})
						}
					}
				}
			}
		}

		return flag;
	};

	const isValid2 = useMemo(() => hasBase64Tag(percentage), [percentage]);

	// const appointeeAge = percentage?.nomineesFormData.map((items: any) => {
	// 	const dobString = items?.Appointee?.[0]?.dob;
	// 	// console.log("dobString", dobString);

	// 	if (dobString === undefined) {
	// 		return true;
	// 	} else {
	// 		const calculateAge = (dob: string | undefined): boolean => {
	// 			if (!dob) {
	// 				return false;
	// 			}

	// 			const isValidDate = (dateString: string): boolean => {
	// 				const regex = /^\d{4}-\d{2}-\d{2}$/;
	// 				return regex.test(dateString);
	// 			};

	// 			const convertToValidDateFormat = (dateString: string): string => {
	// 				const dobParts = dateString.split(/[-/]/);
	// 				if (dobParts.length === 3) {
	// 					return `${dobParts[2]}-${dobParts[1]}-${dobParts[0]}`;
	// 				}
	// 				return dateString;
	// 			};

	// 			const validDobString = isValidDate(dob)
	// 				? dob
	// 				: convertToValidDateFormat(dob);

	// 			const birthDate = new Date(validDobString);
	// 			const today = new Date();
	// 			const age = today.getFullYear() - birthDate.getFullYear();
	// 			const monthDiff = today.getMonth() - birthDate.getMonth();

	// 			if (
	// 				monthDiff < 0 ||
	// 				(monthDiff === 0 && today.getDate() < birthDate.getDate())
	// 			) {
	// 				return age - 1 >= 18;
	// 			} else {
	// 				return age >= 18;
	// 			}
	// 		};

	// 		const isAgeGreaterThan18 = calculateAge(dobString);

	// 		return isAgeGreaterThan18;
	// 	}
	// });

	const FinalSubmitModal = () => {
		const nomineesData: any = getValues();

		const data: any = nomineesData?.nomineeData?.map((value: any) => {
			if (value.nomineeSentToInsurer === 'Y') {
				const values1 = {
					address1: '',
					address2: ' ',
					address3: '',
					city: '',
					state: '',
					country: '',
					pincode: '',
					fullAddress: '',
				};

				const obt3 = {
					...value,
					...values1,
				};
				return obt3;
			}

			const obj2: any = {
				...value,
			};
			return obj2;
		});

		const nomineeData = {
			...nomineesData,
			nomineeData: data,
		};

		const filteredNomineeData = nomineeData.nomineeData.filter(
			(formData: any) => formData.type !== 'ADD'
		);
		const uniqueNomineeData: any[] = [];

		const changeForm = filteredNomineeData.map((formData: any) => {
			if (
				!uniqueNomineeData.some(
					(data) => (data.nomineeFirstName === formData.nomineeFirstName && data.nomineeMiddleName === formData.nomineeMiddleName && data.nomineeLastName === formData.nomineeLastName)
				)
			) {
				uniqueNomineeData.push(formData);
			}
			return {
				...formData,
				type: 'CHANGE',
			};
		});

		const formData = {
			addForm: addFormData,
			changeForm,
			removeForm: removeFormData,
		};

		dispatch(
			updateNomineeRequest({
				particularCardData: cardData,
				allFormData: formData,
				navigate,
				dispatch,
			})
		);

		// setsubmitModalVisible(false);
	};

	// eslint-disable-next-line consistent-return
	const onSubmit = (data: any) => {
		const filteredData = data.nomineeData.filter(
			(formData: any) => formData.type === 'ADD'
		);
		// const existingNominees = filteredData.filter((formData: any) => {
		// 	const originalData = data.nomineeData.find((originalFormData: any) => {
		// 		if (originalFormData.type === 'ADD' && originalFormData !== formData) {
		// 			return (
		// 				originalFormData.nomineeFirstName === formData.nomineeFirstName &&
		// 				// originalFormData.nomineePercentage === formData.nomineePercentage &&
		// 				originalFormData.relationshipName === formData.relationshipName &&
		// 				originalFormData.relationshipCode === formData.relationshipCode
		// 				// originalFormData.date === formData.date &&
		// 				// originalFormData.month === formData.month &&
		// 				// originalFormData.year === formData.year
		// 			);
		// 		}
		// 		return false;
		// 	});
		// 	return !originalData;
		// });
		const arrayData = data.nomineeData;
		const resultArray = arrayData
			.map((value: any) => value.nomineeFirstName + value.nomineeMiddleName + value.nomineeLastName + value.relationshipName)
			.some(
				(value: any, index: any, array: any) =>
					array.indexOf(value) !== array.lastIndexOf(value)
			);

		if (resultArray) {
			const duplicateEntry = arrayData.find(
				(value: any, index: any, array: any) =>
					array.findIndex(
						(otherValue: any) =>
							otherValue !== value &&
							otherValue.nomineeFirstName === value.nomineeFirstName &&
							otherValue.nomineeMiddleName === value.nomineeMiddleName &&
							otherValue.nomineeLastName === value.nomineeLastName &&
							otherValue.relationshipName === value.relationshipName &&
							otherValue.relationshipCode === value.relationshipCode
					) !== -1
			);

			const duplicateName = duplicateEntry?.nomineeFirstName;

			const alertMessage = `${duplicateName} is already exists as a nominee. Please select another person.`;
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: alertMessage,
				})
			);
		} else {
			setAddFormData(filteredData);
			if (!isValid) {
				return false;
			}
			setsubmitModalVisible(true);
		}
	};

	const { t } = useTranslation();

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Edit Nominees Screen',
		});
	}, []);

	return (
		<Grid
			container
			justifyContent="center"
		// style={{ border: '2px solid black' }}
		>
			<Grid
				item
				lg={7}
				xl={7}
				md={7}
				xs={12}
				sm={7}
				container
				justifyContent="center"
			// style={{ border: '2px solid pink' }}
			>
				<Grid
					item
					lg={9}
					xl={12}
					md={12}
					xs={12}
					sm={12}
					container
					className=""
				// style={{ border: '2px solid blue' }}
				>
					<Grid item xs={12}>
						<Typography
							fontSize={16}
							fontWeight={600}
							color={theme.palette.primary.main}
							className="mb-5"
						>
							{t('EDIT_NOMINEES')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid
							container
							lg={12}
							xl={12}
							md={12}
							xs={12}
							sm={12}
							className="ml-10 py-10"
						>
							<Grid item xs={6}>
								<Link to={APP_ROUTES.NOMINEE}>
									<CustomButton
										className=" fw-400"
										text={t('CANCEL')}
										variant="text"
										color="primary"
										showIcon={false}
										startIcon={Cancel}
									/>
								</Link>
							</Grid>
							<Grid item xs={6}>
								<CustomButton
									className=" fw-600 "
									text={t('SUBMIT')}
									variant="text"
									color="primary"
									showIcon={false}
									startIcon={Correct}
									disabled={sumOfPercentages !== 100 || isValid2}
									onClick={handleSubmit(onSubmit)}
								/>
								<NomineeModal
									open={submitModalVisible}
									setOpen={setsubmitModalVisible}
									FinalSubmitModal={FinalSubmitModal}
									isLoading={isNomineeUpdatingLoading}
								/>
							</Grid>
						</Grid>

						<Grid item>
							<NomineeCardHeader
								productName={cardData.planName}
								selfName={cardData.lifeAssuredName}
								sumAssured={cardData.sumAssured}
								premium={cardData.totalPremiumAmount}
								bankImage={cardData.companyLogo}
							/>
						</Grid>
						<Grid
							item
							lg={12}
							xl={12}
							md={12}
							xs={12}
							sm={12}
							className="mb-10"
						>
							<Separator color={theme.palette.primary.main} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid lg={12} xl={9} md={12} xs={12} sm={12} container className="">
							<Grid container justifyContent="space-between" flexWrap="nowrap">
								<Grid item>
									<Typography
										fontSize={14}
										fontWeight={600}
										color={theme.palette.primary.main}
									>
										{t('NOMINEE(S)')}
									</Typography>
								</Grid>
								<Grid item flexWrap="nowrap">
									<Typography
										fontSize={14}
										fontWeight={400}
										color={theme.palette.error.main}
									>
										{t('TOTAL_100%')}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										fontSize={14}
										fontWeight={400}
										color={
											sumOfPercentages !== 100
												? theme.palette.error.main
												: theme.palette.primary.main
										}
									>
										{sumOfPercentages}%
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={12} className="ml-10 py-10">
								{numberOfForms < nomineeCount && (
									<CustomButton
										className=" f-14"
										text={t('ADD_A_NOMINEE')}
										variant="text"
										color="primary"
										showIcon={false}
										startIcon={Add}
										onClick={handleAddModal}
									/>
								)}
								<NomineeListModal
									open={addModalVisible}
									setOpen={setAddModalVisible}
									addNomineeCloseModal={addNomineeCloseModal}
									addNomineeModal={addNomineeModal}
								/>
							</Grid>
							<Grid item container xs={12}>
								{fields.map((item: any, index: number) => (
									<Grid item key={index} xs={12}>
										<NomineeForm
											item={item}
											index={index}
											key={item.id}
											setValue={setValue}
											register={register}
											errors={errors}
											watch={watch}
											submit={handleSubmit(onSubmit)}
											onRemove={() => removeNominee(index)}
											addNomineeModal={addNomineeModal}
											numberOfForms={numberOfForms}
											// nomineeCount={cardData?.nomineeCount}
											cardData={cardData}
											unregister={unregister}
											control={control}
											getFieldState={getFieldState}
										/>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<ApiModal />
		</Grid>
	);
};

export default EditNominee;
