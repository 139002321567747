import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';

import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import { convertAmount } from '../../../../../../common/utils';

interface VehicleProps {
	vehicleDetails?: any;
}
const VehicleInformation: React.FC<VehicleProps> = ({ vehicleDetails }) => {
	const { t } = useTranslation();

	return (
		<Grid container>
			<Grid item xs={12} className="py-5">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
					className="mb-5"
				>
					{t('VEHICLE_INFORMATION')}
				</Typography>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('VEHICLE_MODEL_&_COLOR')}
				</Typography>
				<Typography
					color={theme.palette.common.black}
					fontSize={18}
					fontWeight={600}
				>
					{'Name of model'}:{' '}
					{vehicleDetails?.nameOfModel?.length === 0
						? '-'
						: vehicleDetails?.nameOfModel}
				</Typography>
				<Typography color={theme.palette.common.black} fontSize={18}>
					{vehicleDetails?.color ? vehicleDetails?.color : '-'}
				</Typography>
			</Grid>

			<Grid item container xs={12} className="py-5">
				<Grid item xs={6}>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('REGISTRATION_NUMBER')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={16}
						fontWeight={600}
					>
						{vehicleDetails?.registrationNo.length === 0
							? '-'
							: vehicleDetails?.registrationNo}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('PLACE')}
					</Typography>
					<Typography color={theme.palette.common.black} fontSize={16}>
						{vehicleDetails?.registrationPlace.length === 0
							? '-'
							: vehicleDetails?.registrationPlace}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('TOTAL_IDV')}
					</Typography>
					<LabelWithIcon
						className=""
						svgClassName="mb-2 "
						justifyContent="flex-start"
						Icon={OrangeRuppee}
						label={
							vehicleDetails?.totalIDV.length === 0
								? '-'
								: convertAmount(vehicleDetails?.totalIDV)
						}
						labelProps={{
							color: theme.palette.common.black,
							fontSize: 16,
							fontWeight: 600,
						}}
					/>
				</Grid>
				<Grid item xs={12} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('MANF_COMPANY_NAME')}
					</Typography>
					<Typography color={theme.palette.common.black} fontSize={16}>
						{vehicleDetails?.manfCompanyName.length === 0
							? '-'
							: vehicleDetails?.manfCompanyName}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('MODEL/MANF_YEAR')}
					</Typography>
					<Typography color={theme.palette.common.black} fontSize={18}>
						{vehicleDetails?.modelManfYear.length === 0
							? '-'
							: vehicleDetails?.modelManfYear}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('VEHICLE_CLASS')}
					</Typography>
					<Typography color={theme.palette.common.black} fontSize={18}>
						{vehicleDetails?.vehicleClass.length === 0
							? null
							: vehicleDetails?.vehicleClass}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('CARRYING_CAPACITY')}
					</Typography>
					<Typography color={theme.palette.common.black} fontSize={18}>
						{vehicleDetails?.carryingCapacity.length === 0
							? '-'
							: vehicleDetails?.carryingCapacity}{' '}
						{t('SEATER')}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('BODY_TYPE')}
					</Typography>
					<Typography color={theme.palette.common.black} fontSize={18}>
						{vehicleDetails?.bodytype.length === 0
							? '-'
							: vehicleDetails?.bodytype}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('CHASSIS_NUMBER')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						className="break-word mr-20"
					>
						{vehicleDetails?.chassisNumber.length === 0
							? '-'
							: vehicleDetails?.chassisNumber}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('ENGINE_NUMBER')}
					</Typography>
					<Typography color={theme.palette.common.black} fontSize={18}>
						{vehicleDetails?.engineNumber.length === 0
							? '-'
							: vehicleDetails?.engineNumber}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('CUBIC_CAPACITY')}
					</Typography>
					<Typography color={theme.palette.common.black} fontSize={18}>
						{vehicleDetails?.cubicCapacity.length === 0
							? '-'
							: vehicleDetails?.cubicCapacity}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('FUEL_TYPE')}
					</Typography>
					<Typography color={theme.palette.common.black} fontSize={18}>
						{vehicleDetails?.fuelType.length === 0
							? '-'
							: vehicleDetails?.fuelType}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default VehicleInformation;
