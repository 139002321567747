import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import Separator from '../../../../../../common/ui/Separator';
import CustomButton from '../../../../../../common/ui/CustomButton';
import StampDetails from '../common/StampDetails';
import { convertAmount } from '../../../../../../common/utils';

interface FundProps {
	fundDetails?: any;
}
const Fund: React.FC<FundProps> = ({ fundDetails }) => {
	const fundList = fundDetails?.funds;
	const { t } = useTranslation();
	return (
		<Grid container>
			<Grid
				item
				container
				xs={11}
				display="flex"
				justifyContent="space-between"
				className="py-10"
			>
				<Grid item>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('CURRENT_FUND_VALUE')}
					</Typography>
					<LabelWithIcon
						svgClassName="mb-2"
						justifyContent="flex-start"
						Icon={OrangeRuppee}
						label={convertAmount(fundDetails?.currentFundValue)}
						labelProps={{
							color: theme.palette.common.black,
							fontSize: 18,
						}}
					/>
				</Grid>
				{process.env.REACT_APP_MODE !== 'PROD' && (
					<Grid item>
						<CustomButton
							text={t('CHANGE_LOAN')}
							variant="text"
							color="primary"
							showIcon={false}
							fontSize={14}
						/>
					</Grid>
				)}
			</Grid>
			<Grid item xs={12}>
				<Separator color={theme.palette.grey[400]} />
			</Grid>
			<Grid
				item
				container
				xs={11}
				display="flex"
				justifyContent="space-between"
				className="py-10"
			>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('FUNDS')}
				</Typography>
				{process.env.REACT_APP_MODE !== 'PROD' && (
					<CustomButton
						text={t('SWITCH_FUNDS')}
						variant="text"
						color="primary"
						showIcon={false}
						fontSize={14}
					/>
				)}
			</Grid>
			<Grid item xs={11}>
				{fundList && fundList?.length > 0
					? fundList.map((mapdata: any, index: any) => (
							<Grid item container xs={12} className="py-10">
								<Grid item xs={1}>
									<Typography color={theme.palette.common.black} fontSize={18}>
										{index + 1}.
									</Typography>
								</Grid>
								<Grid item xs={10}>
									<Typography color={theme.palette.common.black} fontSize={13}>
										{mapdata.fundName}
									</Typography>
									<Typography color={theme.palette.common.black} fontSize={13}>
										{mapdata.sfinNo}
									</Typography>
								</Grid>
							</Grid>
					  ))
					: '-'}
			</Grid>
			<Grid item xs={12}>
				<Separator color={theme.palette.grey[400]} />
			</Grid>
			<StampDetails stampData={fundDetails} />{' '}
		</Grid>
	);
};
export default Fund;
