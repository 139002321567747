import React, { useState, useEffect, useRef } from 'react';
import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import './AddFamilyMember.scss';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import DatePickerModal from '../../../../common/ui/DatePickerModal';
import CustomButton from '../../../../common/ui/CustomButton';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import data from '../../../../common/constants/FormConstants.json';
import {
	addFamilyMemberRequest,
	fetchUserProfile,
	selectUserFamilyList,
	selectUserProfile, selectIsAddFamilyMemberLoading
} from '../Profile.slice';
import { selectIsUserLoggedIn } from '../../../Auth/auth.slice';
import FormCheckBox from '../../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
import AddFamilyAddress from './AddFamilyAddress';
import AddFamilyProfileUploader from './AddFamilyProfileUploader';
import ImagePickerModal from '../../../../common/ui/Modal/ImagePickerModal';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as ArrowLeftIcon } from '../../../../common/icons/left_arrow-icon.svg';
import { ReactComponent as cancel } from '../../../../common/icons/cancel-icon.svg';
import { ReactComponent as correct } from '../../../../common/icons/tick-icon.svg';
import { countryCode } from '../../../../common/utils';
import config from '../../../../common/config';
import ApiModal from '../../../../common/ui/Modal/ApiModal';
import dayjs from 'dayjs';
import Spinner from '../../../../common/ui/Spinner';
import RemoveMemberModal from './RemoveMemberModal';

const AddFamilyMember = ({ isEdit, memberData, familyListData, closeMember, mergeInfo }: any) => {
	const location = useLocation();
	const { t } = useTranslation();
	const Relationdata = location.state ? location.state.data : null;
	const RelationShipType = location?.state;
	const familyList = useSelector(selectUserFamilyList);
	const buttonRef: any = useRef();

	const theme = useTheme();
	const dispatch = useDispatch();
	const [imageBase64, setImageBase64] = useState('');
	const [remove, setRemove] = useState(false);
	// const relationshipRelatedData = familyListData.filter((data: any) => data.relationCode == memberData.relationCode && (data.firstName + data.lastName + data.middleName).toLowerCase() == meergedName.toLowerCase());

	useEffect(() => setImageBase64(memberData?.imageBase64), [memberData])

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		watch,
		control,
		trigger,
	} = useForm({
		defaultValues: {
			flag: '',
			mobileCountryCode: '91',
			firstName: memberData?.firstName || '',
			middleName: memberData?.middleName || '',
			lastName: memberData?.lastName || '',
			email: memberData?.email || '',
			mobileNumber: memberData?.mobNumber || '',
			mobNoVerified: memberData?.mobNoVerified || '',
			emailVerified: memberData?.emailVerified || '',
			dob: memberData?.dob || '',
			address1: memberData?.address1 || '',
			address2: memberData?.address2 || '',
			address3: memberData?.address3 || '',
			city: memberData?.city || '',
			state: memberData?.state || '',
			pin: memberData?.pin || '',
			country: memberData?.country || '',
			permanentAddress: memberData?.permanentAddress || false,
			seqId: memberData?.seqId || '',
			alternateCode: memberData?.alternateCode || '',
			authorizedPerson: memberData?.authorizedPerson || '',
			familyFlag: memberData?.flag || '',
			family: memberData?.familyFlag || '',
			nominee: memberData?.nomineeFlag || '',
			nomineeRef: memberData?.nomineeRef || '',
			nomineeName: memberData ? ((memberData?.firstName).concat("", memberData?.middleName).concat("", memberData?.lastName)).replaceAll(" ", '') : '',
			nomineeDob: memberData?.dob || '',
			nomineeRelation: memberData?.originalRelationCode === '-1' ? '' : memberData?.originalRelationCode !== '' ? memberData?.originalRelationCode : memberData?.relationCode || ''
		},
	});

	const triggerRemove = () => {
		setValue("flag", "REMOVE")
		setRemove(false);
		buttonRef.current?.click();

	};

	const dobValue = String(watch('dob')).split('/');

	const handleDateChange = (date: any) => {
		const formattedDate = date?.format('DD/MM/YYYY');
		setValue('dob', formattedDate, { shouldValidate: true });
	};

	const permanentAddress = watch('permanentAddress');
	const mobileNumber = watch('mobileNumber');
	const allowedRelationCodes = ['02', '03', '07', '06'];
	const relationCode =
		RelationShipType === 'father' ? '02' : Relationdata ? Relationdata?.relationCode : memberData?.relationCode;

	const userInfo = useSelector(selectIsUserLoggedIn);
	const addMemberLoader = useSelector(selectIsAddFamilyMemberLoading);
	const navigation = useNavigate();
	const onSubmit = (data: any) => {
		const isRelationCodeExists = familyList?.filter((item: any) => item?.relationCode === relationCode);
		const handleSuccess = () => {
			if (isEdit) {
				closeMember(false);
				if (

					memberData?.authorizedPerson === 'Y' && data?.flag === "REMOVE"
				) {
					navigation(APP_ROUTES.PROFILE_AR_SCREEN);
				}

			} else {
				navigation(APP_ROUTES.FAMILYDETAILS);
			}
		}
		const payloads = {
			FamilyMember: {
				flag: data?.flag === "REMOVE" ? "REMOVE" : isEdit ? "CHANGE" : "ADD",
				relationCode,
				eiaNoFamily: userInfo?.eiaNo,
				firstName: data.firstName,
				middleName: data.middleName ? data.middleName : '',
				lastName: data.lastName ? data.lastName : '',
				imageBase64: imageBase64 || '',
				email: data.email || '',
				mobCountryCode: data.mobCountryCode || '',
				mobNumber: data.mobileNumber,
				mobNoVerified: (memberData?.mobNumber !== data?.mobileNumber) ? 'N' : (data?.mobNoVerified || 'N'),
				emailVerified: (memberData?.email !== data?.email) ? 'N' : (data?.emailVerified || 'N'),
				dob: dayjs(data?.dob, 'DD/MM/YYYY').format('DD-MM-YYYY') !== "Invalid Date" ? dayjs(data?.dob, 'DD/MM/YYYY').format('DD-MM-YYYY') : "",
				address1: data.address1,
				address2: data.address2,
				address3: data.address3,
				city: data.city,
				state: data.state,
				country: 'India',
				pin: data.pin,
				authorizedPerson: data?.authorizedPerson,
				addressSameAsLifeAssured: data?.permanentAddress === true ? 'Y' : 'N',
				seqId: data.seqId,
				alternateCode: data.alternateCode,
				familyFlag: data?.familyFlag,
				family: data?.family,
				nominee: data?.nominee,
				nomineeRef: data?.nomineeRef,
				nomineeName: data?.nomineeName || '',
				nomineeDob: data?.nomineeDob || '',
				nomineeRelation: data?.nomineeRelation || '',
				mergeRelationshipFlag: 'N'
			},
			handleSuccess,
		};



		if (isRelationCodeExists.length > 0 && allowedRelationCodes.includes(relationCode) && isRelationCodeExists?.[0]?.seqId !== memberData?.seqId) { //&& isRelationCodeExists?.seqId !== memberData.seqId

			const payload = {
				...memberData,
				mobileCountryCode: '91',
				firstName: data?.firstName || '',
				middleName: data?.middleName || '',
				lastName: data?.lastName || '',
				email: data?.email || '',
				mobNumber: data?.mobileNumber || '',
				dob: data?.dob || '',
				address1: data?.address1 || '',
				address2: data?.address2 || '',
				address3: data?.address3 || '',
				city: data?.city || '',
				state: data?.state || '',
				pin: data?.pin || '',
				country: data?.country || '',
				authorizedPerson: data?.authorizedPerson,
				permanentAddress: data?.permanentAddress === true ? 'Y' : 'N',
				seqId: data?.seqId || '',
				alternateCode: data?.alternateCode || '',
			}
			mergeInfo(payload, "fatherLogic", []);
		} else if (!allowedRelationCodes.includes(relationCode)) {
			const payload = {
				...memberData,
				mobileCountryCode: '91',
				firstName: data?.firstName || '',
				middleName: data?.middleName || '',
				lastName: data?.lastName || '',
				email: data?.email || '',
				mobNumber: data?.mobileNumber || '',
				dob: data?.dob || '',
				address1: data?.address1 || '',
				address2: data?.address2 || '',
				address3: data?.address3 || '',
				city: data?.city || '',
				state: data?.state || '',
				pin: data?.pin || '',
				country: data?.country || '',
				authorizedPerson: data?.authorizedPerson,
				permanentAddress: data?.permanentAddress === true ? 'Y' : 'N',
				seqId: data?.seqId || '',
				alternateCode: data?.alternateCode || '',
				mergeRelationshipFlag: 'N'
			}
			if (familyListData) {
				debugger;
				const mName = (memberData?.firstName + memberData?.middleName + memberData?.lastName).toLowerCase();
				const mDate = dayjs(memberData?.dob, 'DD/MM/YYYY').format('DD-MM-YYYY');
				let filterMemberData = familyListData.filter((d: any) => d.relationCode === memberData.relationCode && (((d.firstName + d.middleName + d.lastName).toLowerCase()).replace(/\s/g, "") !== mName.replace(/\s/g, "") || (d.dob !== mDate))
				);


				let date_formated = dayjs(data?.dob, 'DD/MM/YYYY').format('DD-MM-YYYY');
				let meergedName = (data?.firstName + data?.middleName + data?.lastName).replace(/\s/g, "");;
				let relationshipRelatedData = filterMemberData.filter((d: any) => (d.firstName + d.middleName + d.lastName).replace(/\s/g, "").toLowerCase() === (meergedName.replace(/\s/g, "")).toLowerCase() && d.dob === date_formated);

				if (relationshipRelatedData.length > 0) {
					const payload = {
						...relationshipRelatedData?.[0],
						mobileCountryCode: '91',
						firstName: data?.firstName || '',
						middleName: data?.middleName || '',
						lastName: data?.lastName || '',
						email: data?.email || '',
						mobNumber: data?.mobileNumber || '',
						dob: data?.dob || '',
						address1: data?.address1 || '',
						address2: data?.address2 || '',
						address3: data?.address3 || '',
						city: data?.city || '',
						state: data?.state || '',
						pin: data?.pin || '',
						country: data?.country || '',
						authorizedPerson: data?.authorizedPerson,
						permanentAddress: data?.permanentAddress === true ? 'Y' : 'N',
						seqId: data?.seqId || '',
						alternateCode: data?.alternateCode || '',
						merge_key: true,
					}
					mergeInfo(payload, "sisLogic", relationshipRelatedData?.[0]);
				} else {
					dispatch(addFamilyMemberRequest(payloads as any));
				}
			} else {
				dispatch(addFamilyMemberRequest(payloads as any));
			}
		}
		else {
			dispatch(addFamilyMemberRequest(payloads as any));
		}



	};

	useEffect(() => {
		if (memberData?.dob) {
			const d = dayjs(memberData?.dob, "DD-MM-YYYY");
			handleDateChange(d);
		}

	}, []);
	const userProfile = useSelector(selectUserProfile);
	const permanentEiaAddress: any =
		userProfile?.Customer?.PermanentAddress || {};

	const handleChange = () => {
		setValue('permanentAddress', !permanentAddress);

		if (!permanentAddress) {
			setValue('address1', (permanentEiaAddress?.address1 || ''), { shouldValidate: false });
			setValue('address2', permanentEiaAddress?.address2 || '', { shouldValidate: false });
			setValue('address3', permanentEiaAddress?.address3 || '', { shouldValidate: false });
			setValue('city', permanentEiaAddress?.city || '', { shouldValidate: false });
			setValue('country', permanentEiaAddress?.country || '', { shouldValidate: false });
			setValue('pin', permanentEiaAddress?.pincode || '', { shouldValidate: false });
			setValue('state', permanentEiaAddress?.state || '', { shouldValidate: false });
		} else {
			setValue('address1', memberData?.address1, { shouldValidate: true });
			setValue('address2', memberData?.address2, { shouldValidate: true });
			setValue('address3', memberData?.address3, { shouldValidate: true });
			setValue('city', memberData?.city, { shouldValidate: true });
			setValue('country', memberData?.country, { shouldValidate: true });
			setValue('pin', memberData?.pin, { shouldValidate: true });
			setValue('state', memberData?.state, { shouldValidate: true });
		}
	};
	const mobileNumberRegex = /^[6789]\d{9,}$/;
	const [open, setOpen] = useState(false);
	const handleUpload = () => {
		setOpen(true);
	};
	const processImage = (imageBase64: string) => {
		try {
			if (imageBase64) {
				setImageBase64(imageBase64);
			}
			setOpen(false);
		} catch (e) {
			// eslint-disable-next-line no-console
		}
	};
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);

		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}

		return '';
	};

	useEffect(() => {
		if (mobileNumber) {
			setValue('mobileNumber', parseMobileNo(mobileNumber));
		}
	}, [mobileNumber]);

	const handleMiddleNameInput = (event: any) => {
		const currentValue = event.target.value;
		const newValue = currentValue.replace(/[^a-zA-Z\s]/g, '');
		if (/^\d$|^[^a-zA-Z\s]$/.test(newValue)) {
			event.target.value = '';
		} else {
			event.target.value = newValue;
		}
	};

	// const isMinor: any = useMemo(
	// 	() =>
	// 		(ageCalculator(`${dobValue[1]}/${dobValue[0]}/${dobValue[2]}`) as any) >
	// 		18,
	// 	[dobValue]
	// );

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const datePicker = (e: any) => {
		setAnchorEl(e.currentTarget);
	};
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('email', emailValue);
		trigger('email');
	};
	const nameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue('firstName', sanitizedValue, { shouldValidate: true });
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container className="h-100 py-15" justifyContent="center">
				<Grid item xs={12} xl={isEdit ? 11 : 7} lg={isEdit ? 11 : 7} md={isEdit ? 11 : 7} sm={10} container className="">
					<Grid container spacing={2}>
						{!isEdit && <Grid item>
							<Link to={APP_ROUTES.PROFILE}>
								<Grid item>
									<SvgConverter
										Icon={ArrowLeftIcon}
										className="go-back-icon d-flex"
									/>
								</Grid>
							</Link>
						</Grid>}
						<Grid item>
							<Typography
								className="f-16 fw-700 mb-10 add-member-style"
								color="primary"
							>
								{isEdit ? t('EDIT_MEMEBER') : t('ADD_A_MEMBER')}-{' '}
								{RelationShipType === 'father' ? (
									<Typography
										className="f-16 fw-400 mb-10 ml-5"
										color="primary"
									>
										{t('FATHER')}
									</Typography>
								) : (
									<Typography
										className="f-16 fw-400 mb-10 ml-5"
										color="primary"
									>
										{isEdit ? memberData.relationName : Relationdata?.relationship}
									</Typography>
								)}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} xl={isEdit ? 11 : 7} lg={isEdit ? 11 : 7} md={isEdit ? 11 : 7} sm={10} container className="">
					{isEdit &&
						<Grid item container xs={12} className="mt-15 mb-5">
							<Grid item className='mt-0 mb-20'>

								<CustomButton
									className="f-14 ml-8"
									text={t('REMOVE MEMBER')}
									variant="text"
									color="primary"
									showIcon={false}
									loading={addMemberLoader}
									startIcon={cancel}
									onClick={() => setRemove(true)}
								/>

							</Grid>
							<Grid
								container
								spacing={2}
								justifyContent="space-between"
								className="mb-14"
							>
								<Grid item>
									{isEdit ?
										<CustomButton
											className="f-14 ml-8"
											text={t('CANCEL')}
											variant="text"
											color="primary"
											showIcon={false}
											startIcon={cancel}
											onClick={() => closeMember(false)}
										/>
										: <Link to={APP_ROUTES.PROFILE}>
											<CustomButton
												className="f-14 ml-8"
												text={t('CANCEL')}
												variant="text"
												color="primary"
												showIcon={false}
												startIcon={cancel}
											/>
										</Link>}
								</Grid>
								<Grid item>
									<CustomButton
										className="f-14 fw-600 "
										text={t('DONE')}
										variant="text"
										color="primary"
										loading={addMemberLoader}
										showIcon={false}
										startIcon={correct}
										type="submit"
									/>
								</Grid>
							</Grid>
						</Grid>}
					<Grid item xs={12} container>
						<Grid item>
							<Typography className="f-14 mb-3" color="primary">
								{t('NAME')}*
							</Typography>
						</Grid>{' '}
						<Grid container item columnSpacing={2} rowSpacing={2}>
							<Grid item xs={6} sm={6}>
								{/* <FormInput
								type="text"
								placeholder="First Name"
								name="firstName"
								inputProps={{
									className: '',
									onInput: handleMiddleNameInput,
									...register('firstName', {
										required: {
											value: true,
											message: data.FIRSTNAME,
										},
										// validate: (value) => {
										// 	if (!config.alphabetsOnlyRegex.test(value)) {
										// 		return data.INVALID_NAME;
										// 	}
										// 	return true;
										// },
									}),
								}}
								errors={errors}
							/> */}
								<FormInput
									type="text"
									placeholder={t('TYPE_HERE')}
									name="firstName"
									rules={{
										required: {
											value: true,
											message: t('NAME_IS_REQUIRED'),
										},
										validate: (value: any) => {
											const trimmedValue = value.trim();
											return (
												trimmedValue.length > 0 ||
												`${t('NAME_CANNOT_BE_EMPTY_OR_CONTAIN_ONLY_SPACES')}`
											);
										},
									}}
									inputProps={{
										className: 'px-10',
										maxLength: config.nameLength,
										validate: (value: any) => {
											if (!config.alphabetsOnlyRegex.test(value)) {
												return t('INVALID_NAME');
											}
											return true;
										},

										...register('firstName', {
											onChange: nameChange,
										}),
									}}
									control={control}
									errors={errors}
								/>
							</Grid>

							<Grid item xs={6} sm={6}>
								<FormInput
									type="text"
									placeholder={t('MIDDLE_NAME')}
									name="middleName"
									inputProps={{
										className: '',
										...register('middleName'),
										onInput: handleMiddleNameInput,
									}}
									errors={errors}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						container
						item
						xs={12}
						spacing={1}
						className="mt-2 mb-14"
						rowSpacing={2}
					>
						<Grid item xs={6} sm={6}>
							<FormInput
								type="text"
								placeholder={t('LAST_NAME')}
								name="lastName"
								inputProps={{
									className: '',
									onInput: handleMiddleNameInput,
									...register('lastName', {
										required: {
											value: false,
											message: `${t('LAST_NAME_IS_MANDATORY')}`,
										},
										// validate: (value) => {
										// 	if (!config.alphabetsOnlyRegex.test(value)) {
										// 		return data.INVALID_NAME;
										// 	}
										// 	return true;
										// },
									}),
								}}
								errors={errors}
							/>
						</Grid>
						<Grid item xs={6} sm={6} display="flex">
							<Grid display="flex">
								<AddFamilyProfileUploader
									handleUpload={handleUpload}
									imageBase64={imageBase64}
									relationshipType={relationCode}
								/>

								<Typography
									className="ml-12 f-14"
									flexWrap="wrap"
									color="primary"
								>
									{t('UPLOAD_PIC')}({t('OPTIONAL')})
								</Typography>
								<ImagePickerModal
									open={open}
									setOpen={setOpen}
									handleImage={processImage}
									enableCropping
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						display="flex"
						justifyContent="space-between"
						// alignItems="center"
						container
						xs={12}
						xl={12}
						lg={12}
						md={12}
						sm={12}
					>
						<Grid item xs={12} lg={isEdit ? 11 : 5.9} md={isEdit ? 11 : 5.9} sm={isEdit ? 11 : 5.9} xl={isEdit ? 11 : 5.9}>
							<Grid item>
								<Typography className="f-14 mb-3" color="primary">
									{t('MOBILE_NUMBER')}
								</Typography>
							</Grid>
							<Grid container flexWrap="nowrap" spacing={1} className="mb-10 ">
								<Grid item xs={4} sm={3} md={3}>
									<FormInput
										placeholder="91"
										hideCountButton
										name="mobileCountryCode"
										control={control}
										readOnly
										rules={{
											onChange: (e: any) => {
												setValue(
													'mobileCountryCode',
													countryCode(e.target.value)
												);
											},
										}}
										inputProps={{
											style: { textAlign: 'center' },
										}}
										errors={errors}
									/>
								</Grid>
								<Grid item flex={1}>
									<FormInput
										type="number"
										placeholder="1234567890"
										hideCountButton
										name="mobileNumber"
										control={control}
										rules={{
											pattern: {
												value: mobileNumberRegex,
												message: `${t('INVALID_MOBILE_NUMBER')}`,
											},
											onChange: (e: any) => {
												setValue('mobileNumber', e.target.value, {
													shouldValidate: true,
												});
											},
										}}
										errors={errors}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} lg={isEdit ? 11 : 5.9} md={isEdit ? 11 : 5.9} sm={isEdit ? 11 : 5.9} xl={isEdit ? 11 : 5.9}>
							<Grid item xs={12}>
								<Typography className="f-14 mb-3" color="primary">
									{t('EMAIL')}
								</Typography>
							</Grid>
							<Grid item className="mb-10" xs={12}>
								<FormInput
									type="email"
									placeholder="email@website.com"
									name="email"
									inputProps={{
										className: 'px-10',
										maxLength: config.emailLength,
										...register('email', {
											// required: {
											// 	value: isMinor,
											// 	message: data.EMAIL,
											// },
											onChange: handleEmailChange,
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: `${t('INVALID_EMAIL')}`,
											},
										}),
									}}
									errors={errors}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} container>
						<Grid item>
							<Typography className="f-14 mb-3" color="primary">
								{t('DATE_OF_BIRTH')}*
							</Typography>
						</Grid>
						<Grid
							container
							item
							xs={12}
							sm={12}
							flexDirection="column"
							className="mb-5"
							rowSpacing={3}
						>
							<Grid
								item
								container
								flexDirection="row"
								spacing={1}
								alignItems="center"
								justifyContent="space-between"
								flexWrap="nowrap"
							>
								<Grid item sm={3}>
									<FormInput
										placeholder="DD"
										readOnly
										inputProps={{
											maxLength: 2,
											className: 'text-center',
											value: dobValue?.[0],
										}}
										onClick={datePicker}

									/>
								</Grid>
								<Grid item sm={3}>
									<FormInput
										placeholder="MM"
										readOnly
										inputProps={{
											maxLength: 2,
											className: 'text-center',
											value: dobValue?.[1],
										}}
										onClick={datePicker}

									/>
								</Grid>
								<Grid item sm={4}>
									<FormInput
										placeholder="YYYY"
										readOnly
										inputProps={{
											maxLength: 4,
											className: 'text-center',
											value: dobValue?.[2],
										}}
										onClick={datePicker}

									/>
								</Grid>
								<Grid item>

									<DatePickerModal
										disableFuture
										onChange={handleDateChange}
										iconType="primary"
										modalOpen={anchorEl}
										setModalOpen={setAnchorEl}
									/>
								</Grid>
							</Grid>
							<FormInput
								hidden
								name="dob"
								inputProps={{
									...register('dob', {
										required: {
											value: watch("flag") === "REMOVE" ? false : true,
											message: t('MANDATORY_FIELD'),
										},
									}),
								}}
								errors={errors}
							/>
						</Grid>
					</Grid>

					<Grid
						container
						item
						xs={12}
						justifyContent="space-between"
						className="mt-10"
					>
						<Grid item>
							<Typography className="f-14" color="primary">
								{t('ADDRESS')}
							</Typography>
						</Grid>
						{/* {permanentAddress?.address1 ||
							permanentAddress?.address1 ||
							permanentAddress?.address3 ||
							permanentAddress?.city ||
							permanentAddress?.country ||
							permanentAddress?.pincode ||
							permanentAddress?.state && ( */}
						<Grid item className="mt-n2 ">
							<FormCheckBox
								label={
									<Typography
										className="f-14"
										color={theme.palette.common.black}
									>
										{t('SAME_AS_SELF')}
									</Typography>
								}
								checked={permanentAddress}
								onClick={handleChange}
								size={16}
								checkBoxPadding={1}
							// {...register('permanentAddress')}
							/>
						</Grid>
						{/* )} */}
						<Grid item xs={12}>
							<AddFamilyAddress
								setValue={setValue}
								register={register}
								errors={errors}
								PermanentAddress={permanentAddress}
								permanentEiaAddress={permanentEiaAddress}
								// {...watch()}
								watch={watch}
								control={control}
							/>

						</Grid>
					</Grid>
					{!isEdit && <Grid item container xs={12} className="mt-15 mb-5">
						<Grid
							container
							spacing={2}
							justifyContent="space-between"
							className="mb-14"
						>
							<Grid item>
								<Link to={APP_ROUTES.PROFILE}>
									<CustomButton
										className="f-14 ml-8"
										text={t('CANCEL')}
										variant="text"
										color="primary"
										showIcon={false}
										startIcon={cancel}
										type="submit"
									/>
								</Link>
							</Grid>
							<Grid item>
								{addMemberLoader ? (
									<Grid item>
										<Spinner />
									</Grid>
								) : (
									<>
										<CustomButton
											className="f-14 fw-600 "
											text={t('ADD_MEMBER')}
											variant="text"
											color="primary"
											showIcon={false}
											startIcon={correct}
											type="submit"
											loading={addMemberLoader}
										/>
									</>)}
							</Grid>

						</Grid>
					</Grid>}
					<button ref={buttonRef} type='submit' style={{ display: 'none' }}>asdd </button>
				</Grid>
				<RemoveMemberModal open={remove} setOpen={setRemove} removeMemeber={triggerRemove} removefamily={memberData} />
				<ApiModal />
			</Grid>

		</form>
	);
};

export default AddFamilyMember;
