import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import Separator from '../../../../../../common/ui/Separator';
import { convertAmount, getFormatDate } from '../../../../../../common/utils';

interface CoveredMembersProps {
	CoveredMembersData?: any;
	lifeDetails?: any;
}
const CoveredMembers: React.FC<CoveredMembersProps> = ({
	CoveredMembersData,
	lifeDetails,
}) => {
	const formattedPolicyIssuanceDate =
		lifeDetails && lifeDetails?.policyIssuanceDate
			? getFormatDate(
					lifeDetails && lifeDetails?.policyIssuanceDate,
					'YYYY-MM-DD'
			  )
			: '-';

	const formattedRiskCessation =
		lifeDetails && lifeDetails?.riskCessation
			? getFormatDate(lifeDetails && lifeDetails?.riskCessation, 'YYYY-MM-DD')
			: '-';
	const formattedRiskCommencement =
		lifeDetails && lifeDetails?.riskCommencement
			? getFormatDate(
					lifeDetails && lifeDetails?.riskCommencement,
					'YYYY-MM-DD'
			  )
			: '-';
	const { t } = useTranslation();
	return (
		<Grid container>
			<Grid item xs={11} className="py-10 mt-5">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('COVERED_MEMBERS')}
				</Typography>
			</Grid>

			{CoveredMembersData &&
				CoveredMembersData?.map((mapdata: any, index: any) => (
					<Grid item container xs={11}>
						<Grid item xs={1}>
							<Typography
								color={theme.palette.common.black}
								fontSize={16}
								fontWeight={600}
							>
								{index + 1}.
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<Typography
								color={theme.palette.primary.main}
								fontSize={14}
								fontWeight={600}
							>
								{t('LIFE_ASSURED')}
							</Typography>
							<Typography
								color={theme.palette.common.black}
								fontSize={16}
								fontWeight={600}
								className="mb-10"
							>
								{mapdata?.lifeAssured}
							</Typography>
							<Typography
								color={theme.palette.primary.main}
								fontSize={14}
								fontWeight={600}
							>
								{t('SUM_ASSURED')}
							</Typography>
							<LabelWithIcon
								svgClassName="mb-2"
								justifyContent="flex-start"
								Icon={OrangeRuppee}
								label={
									mapdata?.sumAssured ? convertAmount(mapdata?.sumAssured) : '-'
								}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 16,
									fontWeight: 600,
								}}
								className="mb-10"
							/>
							<Typography
								color={theme.palette.primary.main}
								fontSize={14}
								fontWeight={600}
							>
								{t('PAN')}
							</Typography>
							<Typography
								color={theme.palette.common.black}
								fontSize={14}
								className="mb-10"
							>
								{mapdata?.pan}
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<Typography
								color={theme.palette.primary.main}
								fontSize={14}
								fontWeight={600}
							>
								{t('GENDER')} ,{t('AGE')}
							</Typography>
							<Typography
								color={theme.palette.common.black}
								fontSize={14}
								className="mb-10"
							>
								{mapdata.gender === 'F' ? 'Female' : 'Male'},{' '}
								{mapdata && mapdata?.age ? mapdata?.age : '0'}
								{t('YEARS')}
							</Typography>
							<Typography
								color={theme.palette.primary.main}
								fontSize={14}
								fontWeight={600}
								className="mt-13"
							>
								{t('AGE')}
							</Typography>
							<Typography
								color={theme.palette.common.black}
								fontSize={16}
								fontWeight={600}
								className="mb-10"
							>
								{mapdata?.age}
							</Typography>
							<Typography
								color={theme.palette.primary.main}
								fontSize={14}
								fontWeight={600}
							>
								{t('UID')}
							</Typography>
							<Typography color={theme.palette.common.black} className="mb-10">
								{mapdata && mapdata?.uid.length === 0 ? '-' : mapdata?.uid}
							</Typography>
						</Grid>
					</Grid>
				))}

			<Grid item xs={12} className="py-10">
				<Separator color="#aaaaaa" />
			</Grid>
			<Grid item container xs={12}>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('BOUGHT_FROM')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						className="mb-10"
					>
						{lifeDetails?.boughtFrom ? lifeDetails?.boughtFrom : '-'}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('AGENT_NAME')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						className="mb-10"
					>
						{lifeDetails?.agentName ? lifeDetails?.agentName : '-'}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('POLICY_INSURENCE_DATE')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						className="mb-10"
					>
						{formattedPolicyIssuanceDate}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('POLICY_TERMS')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						className="mb-10"
					>
						{lifeDetails?.policyTerm} {t('YEARS')}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('RISK_COMMENCEMENT')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						className="mb-10"
					>
						{formattedRiskCommencement === 'Invalid Date'
							? '-'
							: formattedRiskCommencement}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('RISK_CESSATION')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						className="mb-10"
					>
						{formattedRiskCessation === 'Invalid Date'
							? '-'
							: formattedRiskCessation}
					</Typography>
				</Grid>
				<Grid item xs={6} className="py-10">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('CATAGORY')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						className="mb-10"
					>
						{lifeDetails?.catagory}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default CoveredMembers;
