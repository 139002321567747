import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import CustomButton from '../CustomButton';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { closePdf, selectPdfState } from '../../../features/App/Common/pdf.slice';
import CommonModal from './CommonModal';
import './pdfModal.css'


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function range(count: number) {
  return Array(count)
    .fill(0)
    .map((_, idx) => idx);
}

interface PDFViewerProps {
  base64?: string;
  initialWidth?: number;
  initialHeight?: number;
}

const PDFModal: React.FC<PDFViewerProps> = ({ base64, initialWidth = 1000, initialHeight = 700 }) => {
  const dispatch = useDispatch();
  const pdfState = useSelector(selectPdfState);
  const [numPages, setNumPages] = useState<number>(0);
  const [dimensions, setDimensions] = useState({ width: initialWidth, height: initialHeight });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth * 0.9;
      const height = window.innerHeight * 0.9;
      setDimensions({ width, height });
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial dimensions
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log(pdfState, "<-----------------check");
  }, [pdfState]);

  const onDocumentLoadSuccess = (doc: any) => {
    setNumPages(doc.numPages);
  };

  const handleClose = () => {
    dispatch(closePdf());
  };

  return (
    <CommonModal
      open={pdfState.open}
      onClose={handleClose}
      boxProps={{ width: dimensions.width, height: dimensions.height }}
      modalClassName="pdf-viewer-modal"
    >
      <div className="pdf-viewer-content">
        <div className="pdf-viewer-header">
          <h1>Document Preview</h1>
          <CustomButton
            text='Close'
            variant="text"
            color="primary"
            showIcon={false}
            fontSize={14}
            onClick={handleClose}
            startIcon={CloseIcon}
          />
        </div>
        <div className="pdf-viewer-container">
          <Document
            file={`data:application/pdf;base64,${base64 || pdfState.pdfUrl}`}
            onLoadError={console.error}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {range(numPages).map((pg) => (
              <React.Fragment key={pg}>
                <Page
                  pageNumber={pg + 1}
                  width={dimensions.width}
                  height={dimensions.height}
                  renderTextLayer={false}
                />
                {pg !== numPages - 1 && <div style={{ margin: '1em' }} />}
              </React.Fragment>
            ))}
          </Document>
        </div>
      </div>
    </CommonModal>
  );
};

export default PDFModal;
