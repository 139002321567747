import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import React from 'react';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRuppee } from '../../../../../common/icons/small-orange-rupee.svg';
import VerificationStatus from './VerificationStatus';
import { convertAmount } from '../../../../../common/utils';

interface UserProps {
	contentType?: string;
	items?: any;
}
const LifeHeader: React.FC<UserProps> = ({ contentType, items }) => {
	const { t } = useTranslation();

	return (
		<Grid container item xs={12} className="px-10 py-15" spacing={2}>
			<Grid item xs={3}>
				<img src={items.companyLogo} className="bank-image" alt="bank" />
				<VerificationStatus status={items.verifiedFlag} classname="ml-5" />
			</Grid>
			<Grid item xs={8} className="ml-6">
				<Typography
					className="f-16 fw-600 mb-0"
					color={theme.palette.primary.main}
				>
					{items.planName.length === 0 ? '-' : items.planName}
				</Typography>
				<Typography className="f-14 mt-3" color={theme.palette.primary.main}>
					{t('LIFE_ASSURED')}
				</Typography>
				<Typography
					className="f-16 fw-600"
					color={
						contentType === 'active'
							? theme.palette.common.black
							: theme.palette.grey.A700
					}
				>
					{items.customerName.length === 0 ? '-' : items.customerName}
				</Typography>
				<Typography className="f-14 mt-3" color={theme.palette.primary.main}>
					{t('SUM_ASSURED')}
				</Typography>
				<LabelWithIcon
					className="mr-10"
					svgClassName=""
					justifyContent="flex-start"
					Icon={OrangeRuppee}
					label={convertAmount(items?.sumAssured)}
					labelProps={{
						color: theme.palette.common.black,
						fontSize: 16,
						fontWeight: 600,
					}}
				/>
				{/* <Typography className="f-14 mt-3" color={theme.palette.primary.main}>
                Maturity Value
            </Typography>
            <LabelWithIcon
                className="mr-10"
                svgClassName=""
                justifyContent="flex-start"
                Icon={OrangeRuppee}
                label={
                     convertAmount(items?.maturityValue)
                }
                labelProps={{
                    color: theme.palette.common.black,
                    fontSize: 16,
                    fontWeight: 600,
                }}
            /> */}
			</Grid>
		</Grid>
	);
};
export default LifeHeader;
