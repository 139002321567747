import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as RupeeIcon } from '../../../../common/icons/dashboard/rupee-icon.svg';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import '../Dashboard.scss';

const PolicyCardCost = ({
	title = '',
	value = 0,
	policyCounts = 0,
	type,
	beneficiary,
}: {
	title?: string | undefined | any;
	value?: number | string | undefined | 0;
	policyCounts?: number | 0;
	type?: any;
	beneficiary?: any;
}) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<Box className="dashboard-policy-cost-container">
			<Typography
				fontWeight={600}
				className="pb-1"
				fontSize={16}
				color={theme.palette.primary.main}
			>
				{t(title)}
			</Typography>
			<Grid item>
				<Grid
					container
					flexWrap="nowrap"
					className="mt-0"
					alignItems="flex-end"
					flexDirection="row"
					spacing={0.5}
				>
					<Grid
						item
						container
						flexWrap="nowrap"
						alignItems="flex-end"
						flexDirection="row"
						sm={6}
					>
						<Grid item className="h-100">
							<SvgConverter
								Icon={RupeeIcon}
								className="d-flex align-items-end mb-4"
							/>
						</Grid>
						<Grid item>
							<Typography
								fontWeight={300}
								fontSize={28}
								lineHeight={1}
								className="ml-6"
							>
								{value}
							</Typography>
						</Grid>
					</Grid>
					<Link to={APP_ROUTES.POLICY} state={type}>
						<Grid item>
							<Typography fontSize={14} className="ml-35">
								{t('FROM')}{' '}
								<span
									className="fw-600"
									style={{ color: theme.palette.primary.main }}
								>
									{policyCounts}
								</span>{' '}
								<span style={{ color: theme.palette.primary.main }}>
									{policyCounts > 1 ? t('POLICIES') : t('POLICY')}
								</span>
							</Typography>
						</Grid>
					</Link>
				</Grid>
			</Grid>
			<Typography
				fontWeight={400}
				fontSize={14}
				lineHeight={1}
				className="ml-18 mt-7"
				color={theme.palette.common.black}
			>
				{beneficiary}
			</Typography>
		</Box>
	);
};

PolicyCardCost.Normal = ({
	title,
	value = 0,
	policyCounts = 0,
	hidePolicyCounts = false,
	hideLastUpdate = true,
	hideValue = false,
	titleClassName = '',
	titleFontWeight = 300,
	updatedDate,
	allValueText1,
	allValueText2,
}: {
	title?: string | undefined | any;
	value?: number | string | undefined | 0;
	policyCounts?: number | 0;
	hidePolicyCounts?: true | false;
	hideLastUpdate?: true | false;
	hideValue?: true | false;
	titleClassName?: string | undefined;
	titleFontWeight?: number | 300;
	updatedDate?: any;
	allValueText1?: any;
	allValueText2?: any;
}) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Box>
			<Grid className="ml-7 mt-5">
				<Typography
					fontWeight={titleFontWeight}
					fontSize={14}
					color={theme.palette.primary.main}
				>
					{t(title)}
				</Typography>
				{hideValue === false && (
					<Grid>
						<Grid
							item
							container
							flexWrap="nowrap"
							alignItems="flex-end"
							flexDirection="row"
						>
							<Grid item className="h-100">
								<SvgConverter
									Icon={RupeeIcon}
									className="d-flex align-items-end mb-4"
								/>
							</Grid>
							<Grid item>
								<Typography
									fontWeight={300}
									fontSize={28}
									lineHeight={1}
									className={`ml-6 ${titleClassName}`}
								>
									{value}
								</Typography>
							</Grid>
						</Grid>
						<Typography
							fontSize={10}
							color={theme.palette.grey[400]}
							className="text-wrap"
						>
							{allValueText1}
						</Typography>
						<Typography
							fontSize={10}
							color={theme.palette.grey[400]}
							className="text-wrap"
						>
							{allValueText2}
						</Typography>
					</Grid>
				)}

				{hidePolicyCounts === false && (
					<Grid item>
						<Typography fontSize={14}>
							{t('FROM')}{' '}
							<span
								className="fw-600"
								style={{ color: theme.palette.primary.main }}
							>
								{policyCounts}
							</span>{' '}
							<span style={{ color: theme.palette.primary.main }}>
								{policyCounts > 1 ? t('POLICIES') : t('POLICY')}
							</span>
						</Typography>
					</Grid>
				)}

				{hideLastUpdate === false && (
					<Grid item>
						<Typography
							fontSize={10}
							color={theme.palette.grey[400]}
							className="text-wrap"
						>
							{/* [Last Updated on {updatedDate}] */}
						</Typography>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

PolicyCardCost.NormalNonGuaranteed = ({
	title,
	value = 0,
	policyCounts = 0,
	hidePolicyCounts = false,
	hideLastUpdate = true,
	hideValue = false,
	titleClassName = '',
	titleFontWeight = 300,
	updatedDate,
	allValueText,
	fontStyle,
	color,
}: {
	title?: string | undefined | any;
	value?: number | string | undefined | 0;
	policyCounts?: number | 0;
	hidePolicyCounts?: true | false;
	hideLastUpdate?: true | false;
	hideValue?: true | false;
	titleClassName?: string | undefined;
	titleFontWeight?: number | 300;
	updatedDate?: any;
	allValueText?: any;
	fontStyle?: any;
	color?: any;
}) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Box>
			<Grid item className="ml-7 mt-5 ">
				<Typography
					fontWeight={titleFontWeight}
					fontSize={14}
					color={color}
					fontStyle={fontStyle}
				>
					{t(title)}
				</Typography>
				{hideValue === false && (
					<Grid container>
						<Grid
							item
							container
							flexWrap="nowrap"
							alignItems="flex-end"
							flexDirection="row"
						>
							<Grid item className="h-70">
								<SvgConverter
									Icon={RupeeIcon}
									className="d-flex align-items-end mb-4"
								/>
							</Grid>
							<Grid item>
								<Typography
									fontWeight={300}
									fontSize={28}
									lineHeight={1}
									className={`ml-6 ${titleClassName} `}
								>
									{value}
								</Typography>
							</Grid>
						</Grid>
						<Typography
							fontSize={10}
							color={theme.palette.grey[400]}
							className="text-wrap"
						>
							{allValueText}
						</Typography>
					</Grid>
				)}

				{hidePolicyCounts === false && (
					<Grid item>
						<Typography fontSize={14}>
							{t('FROM')}
							<span
								className="fw-600 ml-5"
								style={{ color: theme.palette.primary.main }}
							>
								{policyCounts}
							</span>{' '}
							<span style={{ color: theme.palette.primary.main }}>
								{policyCounts > 1 ? t('POLICIES') : t('POLICY')}
							</span>
						</Typography>
					</Grid>
				)}

				{hideLastUpdate === false && (
					<Grid item>
						<Typography
							fontSize={10}
							color={theme.palette.grey[400]}
							className="text-wrap"
						>
							{/* [Last Updated on {updatedDate}] */}
						</Typography>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

export default PolicyCardCost;
