import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import Separator from '../../../../../../common/ui/Separator';

interface StampDetailsProps {
	stampData?: any;
}
const StampDetails: React.FC<StampDetailsProps> = ({ stampData }) => {
	const { t } = useTranslation();

	return (
		<Grid container>
			<Grid item xs={12} className="mb-10">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('STAMP_DUTY_DETAILS')}
				</Typography>
				<Typography color={theme.palette.common.black} fontSize={18}>
					{stampData && stampData?.stampDutyDetails?.length === 0
						? '-'
						: stampData?.stampDutyDetails}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Separator color={theme.palette.grey[400]} />
			</Grid>
			<Grid item xs={12} className="py-10">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('TERMS_&_CONDITIONS_VERSION')}
				</Typography>
				<Typography color={theme.palette.common.black} fontSize={18}>
					{stampData?.termsAndConditionsVersions
						? stampData?.termsAndConditionsVersions
						: '-'}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Separator color={theme.palette.grey[400]} />
			</Grid>
		</Grid>
	);
};
export default StampDetails;
