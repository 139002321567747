import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import React from 'react';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeButton } from '../../../../../common/icons/policy/orange-button.svg';
import { ReactComponent as alertCircle } from '../../../../../common/icons/policy/alert-circle.svg';
import { ReactComponent as OrangeRuppee } from '../../../../../common/icons/small-orange-rupee.svg';
import VerificationStatus from './VerificationStatus';
import { convertAmount } from '../../../../../common/utils';
import SvgConverter from '../../../../../common/SvgConverter';

interface UserProps {
	contentType?: string;
	items?: any;
}
const HealthHeader: React.FC<UserProps> = ({ contentType, items }) => {
	const { t } = useTranslation();

	return (
		<Grid container>
			<Grid container className="px-10 py-15" spacing={2}>
				<Grid item xs={3}>
					<img src={items.companyLogo} className="bank-image" alt="bank" />
					<VerificationStatus status={items.verifiedFlag} classname="ml-5" />
				</Grid>
				<Grid item xs={8} className="ml-4">
					<Typography
						className="f-16 fw-600 mb-5"
						color={theme.palette.primary.main}
					>
						{items.planName ? items.planName : '-'}
					</Typography>
					<Typography
						className="f-14 fw-600"
						color={
							contentType === 'active'
								? theme.palette.common.black
								: theme.palette.grey.A700
						}
					>
						{items?.PlanName}
					</Typography>
					{/* <Typography className="f-14 mt-4" color={theme.palette.primary.main}>
						{t('PRODUCT_TYPE')}
					</Typography>
					<Typography
						className="f-16 fw-600 mb-5"
						color={theme.palette.common.black}
					>
						{items?.productType ? items?.productType : '-'}
					</Typography> */}

					{/* <LabelWithIcon
						svgClassName=""
						justifyContent="flex-start"
						Icon={OrangeButton}
						label={items.policyStatus ? items.policyStatus : '-'}
						labelProps={{
							color: theme.palette.primary.main,
							fontSize: 16,
						}}
					/> */}

					{/* <Typography
						className="f-16 fw-600 mb-5"
						color={theme.palette.common.black}
					>
						{items?.productTypeOthers ? items?.productTypeOthers : '-'}
					</Typography> */}

					{items && items?.floaterInd === '1' ? (
						<>
							<Typography
								color={theme.palette.primary.main}
								fontSize={14}
								fontWeight={400}
							>
								{t('COVERAGE_TYPE')}
							</Typography>
							<Typography
								className="fw-800"
								color={theme.palette.common.black}
								fontSize={14}
							>
								{items?.coverType ? items?.coverType : '-'}
							</Typography>
						</>
					) : (
						<></>
					)}

					{items?.membersCovered && (
						<LabelWithIcon
							svgClassName=""
							justifyContent="flex-start"
							Icon={OrangeButton}
							label={
								items?.membersCovered
									? `${items?.membersCovered} members covered `
									: '-'
							}
							labelProps={{
								color: theme.palette.primary.main,
								fontSize: 16,
							}}
						/>
					)}
					{/* {items?.coPay && ( */}
					<LabelWithIcon
						svgClassName=""
						justifyContent="flex-start"
						Icon={OrangeButton}
						label={
							items.coPay
								? `${items.coPay}  ${t('%')} ${t('CO_PAY')} `
								: t('NO_COPAY')
						}
						labelProps={{
							color: theme.palette.primary.main,
							fontSize: 16,
						}}
					/>
					{/* )} */}

					{items.waitingPeriod && (
						<LabelWithIcon
							svgClassName=""
							justifyContent="flex-start"
							Icon={OrangeButton}
							label={
								items.waitingPeriod
									? `${items.waitingPeriod} ${t('WAITING_PERIODS')} `
									: 'no waiting period'
							}
							labelProps={{
								color: theme.palette.primary.main,
								fontSize: 16,
							}}
						/>
					)}

					<Typography className="f-14 " color={theme.palette.primary.main}>
						{t('SUM_ASSURED')}
					</Typography>
					<LabelWithIcon
						className="px-4 mr-10"
						svgClassName="mt-3"
						justifyContent="flex-start"
						Icon={OrangeRuppee}
						label={convertAmount(
							items?.sumAssured.length === 0 ? '-' : items?.sumAssured
						)}
						labelProps={{
							color: theme.palette.common.black,
							fontSize: 16,
							fontWeight: 600,
						}}
					/>
					{/* <Typography className="f-14 " color={theme.palette.primary.main}>
						{t('TOTAL_COVER')}
					</Typography>
					<LabelWithIcon
						className="px-4 mr-10"
						svgClassName="mt-3"
						justifyContent="flex-start"
						Icon={OrangeRuppee}
						label={convertAmount(1000000)}
						labelProps={{
							color: theme.palette.common.black,
							fontSize: 16,
							fontWeight: 600,
						}}
					/> */}
					{items?.restoration && (
						<Grid display="flex">
							<Typography
								className="f-14 mr-3"
								color={theme.palette.primary.main}
							>
								{t('UNLIMITED_RESTORATION')}
							</Typography>
							<SvgConverter Icon={alertCircle} />
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};
export default HealthHeader;
