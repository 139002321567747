import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import { ReactComponent as Plus } from '../../../../../../common/icons/plus-bold.svg';
import SvgConverter from '../../../../../../common/SvgConverter';
import { convertAmount } from '../../../../../../common/utils';

interface TotalPremiumProps {
	lifeDetails?: any;
}
const TotalPremium: React.FC<TotalPremiumProps> = ({ lifeDetails }) => {
	const { t } = useTranslation();
	return (
		<Grid container className="py-15 mb-10">
			<Grid item xs={11}>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('TOTAL_PREMIUM')}
				</Typography>
			</Grid>
			<Grid item container xs={4}>
				<Grid item xs={0.5} className="">
					<SvgConverter Icon={OrangeRuppee} className=" mt-10" />
				</Grid>
				<Grid item xs={3}>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						fontWeight={600}
						className="ml-3"
					>
						{convertAmount(lifeDetails?.totalPremium)}
					</Typography>
				</Grid>
			</Grid>
			{/* <Grid item xs={5}>
				<Typography color={theme.palette.common.black} fontSize={16}>
					{t('PAID_ANNUALLY')}
				</Typography>
			</Grid> */}
			<Grid item container xs={12} className="py-10 mt-10">
				<Grid item xs={5}>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('TOTAL_POLICY_PREMIUM')}
					</Typography>
				</Grid>
				<Grid item xs={1} />
				<Grid item xs={5}>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('TOTAL_RIDER_PREMIUM')}
					</Typography>
				</Grid>
			</Grid>
			<Grid item container xs={12} className="mb-15">
				<Grid item xs={0.5} className="">
					<SvgConverter Icon={OrangeRuppee} className=" mt-10 ml-5" />
				</Grid>
				<Grid item xs={4.3}>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						fontWeight={600}
					>
						{convertAmount(lifeDetails?.totalPolicyPremium)}
					</Typography>
				</Grid>
				<Grid item xs={1}>
					<SvgConverter Icon={Plus} className=" mt-10" />
				</Grid>
				<Grid item xs={0.8} className="">
					<SvgConverter Icon={OrangeRuppee} className=" ml-10 mt-10" />
				</Grid>
				<Grid item xs={4.3}>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						fontWeight={600}
						className="ml-3"
					>
						{convertAmount(lifeDetails?.totalRiderPremium)}
					</Typography>
				</Grid>
			</Grid>
			<Grid item container xs={11}>
				<Grid item xs={5} className="widget-title widgettitle">
					<Grid item container xs={12}>
						<Grid item xs={12} textAlign="left">
							<Typography color={theme.palette.common.black} fontSize={14}>
								{t('BASE_PREMIUM')}
							</Typography>
							<LabelWithIcon
								svgClassName="mb-2"
								justifyContent="flex-start"
								Icon={OrangeRuppee}
								label={convertAmount(lifeDetails?.basePremium)}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 18,
									fontWeight: 600,
								}}
							/>
						</Grid>
						<Grid
							item
							xs={8}
							textAlign="center"
							display="flex"
							justifyContent="center"
						>
							<SvgConverter Icon={Plus} className=" mt-5" />
						</Grid>
					</Grid>
					<Grid item container xs={12}>
						<Grid item xs={12} textAlign="left">
							<Typography color={theme.palette.common.black} fontSize={14}>
								{t('LOADED_PREMIUM')}
							</Typography>
							<LabelWithIcon
								svgClassName="mb-2"
								justifyContent="flex-start"
								Icon={OrangeRuppee}
								label={convertAmount(lifeDetails?.loadedPremium)}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 18,
									fontWeight: 600,
								}}
							/>
						</Grid>
						<Grid
							item
							xs={8}
							textAlign="center"
							display="flex"
							justifyContent="center"
						>
							<SvgConverter Icon={Plus} className=" mt-5" />
						</Grid>
					</Grid>
					<Grid item container xs={12}>
						<Grid item xs={12} textAlign="left">
							<Typography color={theme.palette.common.black} fontSize={14}>
								{t('TAXES')}
							</Typography>
							<LabelWithIcon
								svgClassName="mb-2"
								justifyContent="flex-start"
								Icon={OrangeRuppee}
								label={convertAmount(lifeDetails?.taxes)}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 18,
									fontWeight: 600,
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={1} />
				<Grid item xs={5} className="widget-title widgettitle">
					<Grid item container xs={12}>
						<Grid item xs={12} textAlign="left">
							<Typography color={theme.palette.common.black} fontSize={14}>
								{t('RIDER_1_PREMIUM')}
							</Typography>
							<LabelWithIcon
								svgClassName="mb-2"
								justifyContent="flex-start"
								Icon={OrangeRuppee}
								label="0"
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 18,
									fontWeight: 600,
								}}
							/>
						</Grid>
						<Grid
							item
							xs={8}
							textAlign="center"
							display="flex"
							justifyContent="center"
						>
							<SvgConverter Icon={Plus} className=" mt-5" />
						</Grid>
					</Grid>
					<Grid item container xs={12}>
						<Grid item xs={12} textAlign="left">
							<Typography color={theme.palette.common.black} fontSize={14}>
								{t('RIDER_2_PREMIUM')}
							</Typography>
							<LabelWithIcon
								svgClassName="mb-2"
								justifyContent="flex-start"
								Icon={OrangeRuppee}
								label="0"
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 18,
									fontWeight: 600,
								}}
							/>
						</Grid>
						<Grid
							item
							xs={8}
							textAlign="center"
							display="flex"
							justifyContent="center"
						>
							<SvgConverter Icon={Plus} className=" mt-5" />
						</Grid>
					</Grid>
					<Grid item container xs={12}>
						<Grid item xs={12} textAlign="left">
							<Typography color={theme.palette.common.black} fontSize={14}>
								{t('RIDER_3_PREMIUM')}
							</Typography>
							<LabelWithIcon
								svgClassName="mb-2"
								justifyContent="flex-start"
								Icon={OrangeRuppee}
								label="0"
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 18,
									fontWeight: 600,
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default TotalPremium;
